import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import {getOriginalBaseHref} from './locationUtil';
import {LocaleService} from '../app/locale.service';
import {TranslateService} from '@ngx-translate/core';
import {Injector} from '@angular/core';
import {LOCATION_INITIALIZED, registerLocaleData} from '@angular/common';

/**
 * Converts the simple string locale into angular compatible string array with locale and country parts separated.
 */
export function convertToAngularLocale(loc: string): string[] {
  let quirkedLoc;
  if (loc === 'no') {
    // angular does not support "no" corectly, so we must override it so support date formatting
    quirkedLoc = ['nb'];
  } else {
    quirkedLoc = loc.split('-');
    if (quirkedLoc.length === 2) {
      quirkedLoc[1] = quirkedLoc[1].toUpperCase();
    }
  }
  return quirkedLoc;
}

/**
 * Imports locale data for exact 2 part locale match and 1 part fallback
 */
export function importLocaleData(angularLocale: string[]) {
  // Angular has made managing localisation super easy and simple.
  // The locale-data must be registered first, before we attempt to use the locale or using the locale will fail.
  // But as our supported locales are dynamic, we don't really know which locales are supported before we attempt use them.
  // So we must first we attempt to lazy import data for the exact requested locale, and then regardless of fail/success
  // lazy import the fallback language data to ensure that there is required locale data avialable if we need to attempt to use the
  // fallback.
  const handleFallback = () => {
    return new Promise<void>((resolveFB, rejectFB) => {
      if (angularLocale.length > 1) {
        importLocaleData([angularLocale[0]])
          .then(() => {
            resolveFB();
          })
          .catch((err) => {
            rejectFB();
          });
      } else {
        resolveFB();
      }
    });
  };

  return new Promise<void>((resolve, reject) => {
    const importPath = angularLocale.join('-');
    import(
      /* webpackChunkName: "locale-data/[request]" */
      `@angular/common/locales/${importPath}.js`)
      .then((lang) => {
        registerLocaleData(lang.default);
        handleFallback().then(resolve).catch(reject);
      })
      .catch((err) => {
        if (angularLocale.length > 1) {
          handleFallback().then(resolve).catch(reject);
        } else {
          reject();
        }
      })
    ;
  });
}

export function getLocalePathParam(): string {
  if (window.location.pathname && window.location.pathname.length > 1) {
    const potential_locale = window.location.pathname.split('/')[1];
    //Regex tailored to allow az, az-az, and az-az9 (last one any combination of three alphanumeric throug a-z, 0-9)
    if (potential_locale && potential_locale.toLowerCase().match(/^[a-z]{2}(-[a-z]{2}|-[a-z0-9]{3})?$/)) {
      return potential_locale;
    }
  }
  return null;
}

class CustomTranslateHttpLoader extends TranslateHttpLoader {
  constructor(http: HttpClient, prefix?: string, suffix?: string) {
    super(http, prefix, suffix);
  }
  getTranslation(lang) {
    let l = lang;
    if (lang === 'nb') {
      l = 'no';
    }
    return super.getTranslation(l.toLowerCase());
  }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new CustomTranslateHttpLoader(http, getOriginalBaseHref() + 'assets/i18n/');
}

export function localisationInitializer(localeService: LocaleService, translate: TranslateService, injector: Injector) {
  return new Promise<any>((resolve: any) => {
    // not sure what this is, docs say it "Indicates when a location is initialized."
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      translate.setDefaultLang('en');
      // check if any locale was requested
      const param_locale = getLocalePathParam();
      if (param_locale) {
        // convert requested locale to angular supported format
        const angularLocale = convertToAngularLocale(param_locale);
        // import potentially needed locale data
        importLocaleData(angularLocale)
          .then(() => {
            localeService.setLocaleParam(param_locale);
            // try using requested locale
            translate.use(angularLocale.join('-')).subscribe(res => {
              // success, all done.
              localeService.setIsAvailable(true);
              resolve(null);
            }, e => {
              // requested locale not supported, check if language is available without the country part, if country part is found
              if (angularLocale.length > 1) {
                translate.use(angularLocale[0]).subscribe(res => {
                  // success, all done.
                  localeService.setIsAvailable(true);
                  resolve(null);
                }, err => {
                  // failed, locale not supported. Leave in path for future support and show notification
                  localeService.setIsAvailable(false);
                  resolve(null);
                });
              } else {
                // failed, locale not supported. Leave in path for future support and show notification
                localeService.setIsAvailable(false);
                resolve(null);
              }
            });
          })
          .catch(() => {
            // failed, locale not supported. Leave in path for future support and show notification
            localeService.setLocaleParam(param_locale);
            localeService.setIsAvailable(false);
            resolve(null);
          })
        ;
      } else {
        // no locale was requested, continue with default.
        localeService.setLocaleParam(null);
        localeService.setIsAvailable(true);
        resolve(null);
      }
    });
  });
}
