
export class AppConstants {
  public static readonly QP_ALLOW_REGISTER = 'allowRegistration';
  public static readonly QP_MESSAGE = 'message';
  public static readonly QP_FLOW = 'flow';
  public static readonly QP_EMAIL = 'email';
  public static readonly QP_LOGIN_HINT = 'login_hint';
  public static readonly QP_EMAIL_VALIDATION_KEY = 'evKey';
  public static readonly QP_EMAIL_VALIDATION_ID = 'evId';
  public static readonly QP_RESET_PASSWORD_KEY = 'rpKey';
  public static readonly QP_NEXT = 'next';
  public static readonly QP_LOGOUT_INITIATOR = 'appId';
  public static readonly QP_LOGOUT_INITIATOR_TYPE = 'appType';
  public static readonly QP_INVITATION_TOKEN = 'invToken';
  public static readonly QP_INVITATION_ID = 'invId';
  public static readonly QP_HANDLE_CHALLENGE = 'handle';
  public static readonly QP_REQUIRE_CHALLENGE = 'require';
  public static readonly QP_ENABLE_FLOW = 'enableFlow';
  public static readonly QP_USER_CODE = 'userCode';
  public static readonly QP_ERROR = 'error';
  public static readonly QP_FORCE = 'force';
  /**
   * Enables automatic "no click" activation of delegated authentication process, if it's the only available flow
   */
  public static readonly QP_EXTERNAL_REDIRECT = 'externalRedirect';
  public static readonly QP_SHOW_REMEMBER_ME = 'showRememberMe';
  public static readonly QP_REMEMBER_ME = 'rememberMe';

  public static readonly FLOW_REGISTER = 'register';
  public static readonly FLOW_LOGIN = 'login';
  public static readonly FLOW_FORGOT_PASSWORD = 'forgotPassword';

  /**
   * List of params relevant only to the authentication process, removed after successful authentication
   */
  public static readonly AUHTENTICATION_PROCESS_QUERY_PARAMS = [
    AppConstants.QP_ALLOW_REGISTER,
    AppConstants.QP_EMAIL,
    AppConstants.QP_LOGIN_HINT,
    AppConstants.QP_RESET_PASSWORD_KEY,
    AppConstants.QP_SHOW_REMEMBER_ME,
    AppConstants.QP_REMEMBER_ME,
    AppConstants.QP_EXTERNAL_REDIRECT,
    AppConstants.QP_ERROR,
    AppConstants.QP_MESSAGE,
    /*
    AppConstants.QP_INVITATION_TOKEN,
    // TODO: should this be in the list, probably not, but who should handle and remove
    AppConstants.QP_REQUIRE_CHALLENGE,
    // TODO: should this be in the list, probably not, but who should handle and remove
    */
  ];

  public static readonly AC_AM_INVITATION_ACCEPTED = 'invitationAccepted';
  public static readonly AC_AM_INVITATION = 'invitation';
  public static readonly AC_AM_VERIFY_EMAIL = 'emailVerification';
  public static readonly AC_AM_ACCEPT_AGREEMENTS = 'acceptAgreements';
  public static readonly AC_AM_ACCEPT_AGREEMENT = 'acceptAgreement';
  public static readonly AC_AM_VERIFY_RECOVERY_EMAIL = 'recoveryEmailVerification';
  public static readonly AC_AM_PASSWORD = 'password';
  public static readonly AC_AM_RESET_PWD = 'resetPassword';
  public static readonly AC_AM_TOTP = 'totp';
  public static readonly AC_AM_DELEGATED = 'delegated';
  public static readonly AC_AM_USER_CODE = 'userCode';

  /**
   * Challenges that are handled by login comp.
   * TODO: verify list
   */
  public static readonly LOGIN_COMPONENT_CHALLENGES = [
    AppConstants.AC_AM_PASSWORD,
    AppConstants.AC_AM_TOTP,
  ];

  public static readonly ROUTE_HOME = '';
  public static readonly ROUTE_BS_SAMPLE = 'BS-SAMPLE';
  public static readonly ROUTE_LOGIN = 'login';
  public static readonly ROUTE_LOGOUT = 'logout';
  public static readonly ROUTE_FORGOT_PASSWORD = 'forgot/password';
  public static readonly ROUTE_RESET_PASSWORD = 'reset/password';
  public static readonly ROUTE_CHANGE_PASSWORD = 'change/password';
  public static readonly ROUTE_MANAGE_EMAILS = 'manage/emails';
  public static readonly ROUTE_CONFIGURE_TOTP = 'configure/totp';
  public static readonly ROUTE_REGISTER = 'register';
  public static readonly ROUTE_VALIDATE_EMAIL = 'validate/email';
  public static readonly ROUTE_VALIDATE_RECOVERY_EMAIL = 'validate/recovery-email';
  public static readonly ROUTE_PROFILE = 'profile';
  public static readonly ROUTE_EDIT_PROFILE = 'edit/profile';
  public static readonly ROUTE_JOIN = 'join';
  public static readonly ROUTE_AGREEMENTS = 'agreements';
  public static readonly ROUTE_MANAGE_AGREEMENTS = 'manage/consent';
  public static readonly ROUTE_DEVICE_AUTH = 'device';

  public static readonly PATH_HOME = '/' + AppConstants.ROUTE_HOME;
  public static readonly PATH_LOGIN = '/' + AppConstants.ROUTE_LOGIN;
  public static readonly PATH_LOGOUT = '/' + AppConstants.ROUTE_LOGOUT;
  public static readonly PATH_FORGOT_PASSWORD = '/' + AppConstants.ROUTE_FORGOT_PASSWORD;
  public static readonly PATH_RESET_PASSWORD = '/' + AppConstants.ROUTE_RESET_PASSWORD;
  public static readonly PATH_CHANGE_PASSWORD = '/' + AppConstants.ROUTE_CHANGE_PASSWORD;
  public static readonly PATH_MANAGE_EMAILS = '/' + AppConstants.ROUTE_MANAGE_EMAILS;
  public static readonly PATH_CONFIGURE_TOTP = '/' + AppConstants.ROUTE_CONFIGURE_TOTP;
  public static readonly PATH_REGISTER = '/' + AppConstants.ROUTE_REGISTER;
  public static readonly PATH_VALIDATE_EMAIL = '/' + AppConstants.ROUTE_VALIDATE_EMAIL;
  public static readonly PATH_VALIDATE_RECOVERY_EMAIL = '/' + AppConstants.ROUTE_VALIDATE_RECOVERY_EMAIL;
  public static readonly PATH_PROFILE = '/' + AppConstants.ROUTE_PROFILE;
  public static readonly PATH_EDIT_PROFILE = '/' + AppConstants.ROUTE_EDIT_PROFILE;
  public static readonly PATH_JOIN = '/' + AppConstants.ROUTE_JOIN;
  public static readonly PATH_AGREEMENTS = '/' + AppConstants.ROUTE_AGREEMENTS;
  public static readonly PATH_MANAGE_AGREEMENTS = '/' + AppConstants.ROUTE_MANAGE_AGREEMENTS;
  public static readonly PATH_DEVICE_AUTH = '/' + AppConstants.ROUTE_DEVICE_AUTH;

  public static readonly PATHS = [
    AppConstants.PATH_HOME,
    AppConstants.PATH_LOGIN,
    AppConstants.PATH_LOGOUT,
    AppConstants.PATH_FORGOT_PASSWORD,
    AppConstants.PATH_RESET_PASSWORD,
    AppConstants.PATH_CHANGE_PASSWORD,
    AppConstants.PATH_MANAGE_EMAILS,
    AppConstants.PATH_CONFIGURE_TOTP,
    AppConstants.PATH_REGISTER,
    AppConstants.PATH_VALIDATE_EMAIL,
    AppConstants.PATH_VALIDATE_RECOVERY_EMAIL,
    AppConstants.PATH_PROFILE,
    AppConstants.PATH_EDIT_PROFILE,
    AppConstants.PATH_JOIN,
    AppConstants.PATH_AGREEMENTS,
    AppConstants.PATH_MANAGE_AGREEMENTS,
    AppConstants.PATH_DEVICE_AUTH
  ];

  // anything but exact keyword null, which is used to support select 'no selection option'
  public static readonly NOT_NULL_PATTERN = /^(?!null$).*/;

  public static readonly SN_LOGIN_EMAIL_VALIDATION_SENT = 'login-email-validation-sent';
  public static readonly SN_LOGIN_EMAIL_VALIDATED = 'login-email-validated';
  public static readonly SN_RECOVERY_EMAIL_VALIDATED = 'recovery-email-validated';
  public static readonly SN_PASSWORD_CHANGED = 'password-changed';

  /**
   * Angular does not allow using these types of things in templates, so a utility function "getAppConstant(key: string)" is used with
   * key = name of the constant, e.g. "ERROR_USER_DATA_CONFLICT". Unfortunately this does not show when analyzing usages.
   */
  public static readonly ERROR_USER_DATA_CONFLICT = 'user_data_conflict';
  public static readonly ERROR_INVALID_ANTI_FORGERY_TOKEN = 'invalid_anti_forgery_token';
  public static readonly ERROR_INVALID_SESSION = 'invalid_session';
  public static readonly ERROR_OAUTH_INVALID_REDIRECT_URI = 'oauth_invalid_redirect_uri';
  public static readonly ERROR_INSUFFICIENT_AUTHENTICATIONS = 'insufficient_authentications';


  public static readonly UI_SESSION_ID = 'uisid';
}
