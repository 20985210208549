import {Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Logger} from 'src/util/logger';
import {Address} from '../../gen/model/address';
import {AuthenticationChallenge} from '../../gen/model/authenticationChallenge';
import {UserForRegisterUser} from '../../gen/model/userForRegisterUser';
import ResponseUtil from '../../util/responseUtil';
import {AppConstants} from '../AppConstants';
import {CountriesService} from '../countries-service';
import {RegionService} from '../region-service';
import {FormInputComponent} from '../form-input/form-input.component';
import {LoginBaseComponent} from '../login-base.component';
import {ParamsService} from '../params.service';
import {AlertHandler} from '../no-concurrent-alerts-handler';
import {cardFooterFeedbackTransition} from '../animations';
import LocationUtil, {getAppBaseHref} from '../../util/locationUtil';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {ConfigurationService} from '../ui-configuration/configuration-service';
import {AppStateService, ParamsForRouteNav} from '../app-state.service';
import {marker as _} from '@biesbjerg/ngx-translate-extract-marker';
import {ProcessingIndicatorService} from '../processing-indicator.service';
import {AcceptAgreementsChallenge, AgreementAcceptance} from '../../gen';
import {
  AcceptAgreementChallengeWithReceivedTime,
  flattenChallenges, selectAgreements,
} from '../../util/AgreementUtils';

const cloneDeep = require('lodash.clonedeep');

interface Item {
  value: string;
  label: string;
}
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    cardFooterFeedbackTransition,
  ],
})
export class RegisterComponent extends LoginBaseComponent implements OnInit, OnDestroy {
  fields;
  registerForm: FormGroup;
  email: string;
  emailAsReadOnly = false;
  hasInvitationAccept = false;
  countryList: Item[];
  regionOptions: Item[];
  regionOptionsCountry: string|undefined;
  message: string|undefined;
  agreements: AcceptAgreementChallengeWithReceivedTime[];

  private errorBody: any;
  private genericError = false;

  consentFailed = false;

  constructor(
    private appStateService: AppStateService,
    private countries: CountriesService,
    private regions: RegionService,
    route: ActivatedRoute,
    router: Router,
    private logger: Logger,
    private alertHandler: AlertHandler,
    private paramsService: ParamsService,
    private translate: TranslateService,
    private processingService: ProcessingIndicatorService,
    hostElement: ElementRef,
    configuration: ConfigurationService
  ) {
    super(route, router, hostElement, configuration);
    this.fields = cloneDeep(this.configuration.getProperties().registrationFields);
  }


  ngOnInit() {
    const handleAgreements =  () => new Promise<void>((resolve) => {
      if (this.fields.find((f) => f.key === 'agreements')) {
        const challs = this.appStateService.getAppState().getAuthenticationState()
          .getRemainingChallenges()
          .filter((c) => c.authenticationMethod === AppConstants.AC_AM_ACCEPT_AGREEMENTS)
          .map((c) => (c as AcceptAgreementsChallenge).userAgreementChallenges)
        ;
        selectAgreements(challs[0], { locale: this.translate.currentLang || this.translate.defaultLang })
          .then(flattenChallenges)
          .then((agrs) => {
            this.agreements = (agrs as AcceptAgreementChallengeWithReceivedTime[]);
            resolve();
          });
      } else {
        this.agreements = undefined;
        resolve();
      }
    });
    handleAgreements().then(() => {
      const controls = {};
      for (let i = 0; i < this.fields.length; i += 1) {
        const validators = [];
        const f = this.fields[i];
        if (f.key !== 'agreements' && f.required) {
          validators.push(Validators.required);
        }
        if (f.key ===  'agreements') {
          if (this.agreements && this.agreements.length) {

            this.agreements.forEach((c, ind) => {
              const agreementValidators = [];
              if (c.required) {
                agreementValidators.push(Validators.requiredTrue);
              }
              controls[f.key + '_' + ind] = new FormControl(false,
                {validators: agreementValidators, updateOn: 'change'});
            });
          } else {
            validators.push(Validators.requiredTrue);
            controls[f.key] = new FormControl(false,
              {validators, updateOn: 'change'});
          }
        } else if (f.key ===  'address-country' && f.required) {
          validators.push(Validators.pattern(AppConstants.NOT_NULL_PATTERN));
          controls['country'] = new FormControl(null, validators);
        } else if (f.key ===  'address-region') {
          controls['region'] = new FormControl(null, validators);
        } else {
          if (f.key ===  'password') {
            validators.push(Validators.pattern(this.configuration.getProperties().passwordValidation));
          } else if (f.key ===  'email') {
            validators.push(Validators.email);
          }
          controls[f.key.replace('address-', '')] = new FormControl('', validators);
        }
      }
      this.registerForm = new FormGroup(controls);

      this.countries.alphabetical().subscribe(res => {
        this.countryList = res.map((n) => ({label: n.name, value: n.code}));
      });
      this.validateConfirmPassword = this.validateConfirmPassword.bind(this);
      this.validateRegion = this.validateRegion.bind(this);
      this.registerForm.setValidators([this.validateConfirmPassword, this.validateRegion]);
      this.subscribeParamsState();
      this.setReady();
    });

  }
  ngOnDestroy() {}

  getAgreementNameLink(a: AcceptAgreementChallengeWithReceivedTime) {
    if (a.location) {
      if (a.agreementType === 'tsAndCs') {
        return '<a class="name" href="' + a.location + '" rel="noopener" target="_blank">' +
          this.translate.instant('register.agreements.tsAndCs-label') + '</a>';
      } else if (a.agreementType === 'marketingConsent') {
        return '<a class="name" href="' + a.location + '" rel="noopener" target="_blank">' +
          this.translate.instant('register.agreements.marketingConsent-label') + '</a>';
      } else {
        return '<a class="name" href="' + a.location + '" rel="noopener" target="_blank">' + a.name + '</a>';
      }
    } else {
      return '<span class="name">' + a.name + '</span>';
    }
  }

  isFieldInvalid(field: string): boolean {
    if (field === 'agreements' &&  this.agreements &&  this.agreements.length > 0) {
      const count = this.agreements.length;
      if (count > 0) {
        for (let i = 0; i < count; i += 1) {
          const tmp = this.isFieldInvalid(field + '_' + i);
          if (tmp) {
            return true;
          }
        }
        return false;
      } else {
        return false;
      }
    } else {
      return this.hasError(field) || this.isInputInvalid(field);
    }
  }

  isInputInvalid(field: string): boolean {
    if (field === 'confirmPassword') {
      return (this.registerForm.get(field).invalid
        || (this.registerForm.invalid && this.registerForm.errors && this.registerForm.errors.mustMatch))
        && this.registerForm.get(field).touched;
    } else if (field === 'region') {
      return (this.registerForm.get(field).invalid
          || (this.registerForm.invalid && this.registerForm.errors && this.registerForm.errors.regionRequired));
    } else if (field.startsWith('agreements_')) {
      const siblingsTouched = [...Array(this.agreements ? this.agreements.length : 1).keys()]
        .map((i) => this.registerForm.get('agreements_' + i)?.touched)
        .find((f) => f)
      ;
      return this.registerForm.get(field).invalid && siblingsTouched;
    } else {
      return this.registerForm.get(field).invalid && this.registerForm.get(field).touched;
    }
  }

  isFieldValid(field: string): boolean {
    if (field === 'agreements' && this.agreements &&  this.agreements.length > 0) {
      const count = this.agreements.length;
      if (count > 0) {
        for (let i = 0; i < count; i += 1) {
          const tmp = this.isFieldValid(field + '_' + i);
          if (!tmp) {
            return false;
          }
        }
        return true;
      } else {
        return true;
      }
    } else {
      return !this.hasError(field) && this.isInputValid(field);
    }
  }

  isInputValid(field: string): boolean {
    if (field === 'confirmPassword') {
      return (this.registerForm.get(field).valid
        && (this.registerForm.valid || !this.registerForm.errors || !this.registerForm.errors.mustMatch))
        && this.registerForm.get(field).touched;
    } else if (field === 'region') {
      return (this.registerForm.get(field).valid
          && (this.registerForm.valid || !this.registerForm.errors || !this.registerForm.errors.regionRequired));
    } else if (field.startsWith('agreements_')) {
      const siblingsTouched = [...Array(this.agreements ? this.agreements.length : 1).keys()]
        .map((i) => this.registerForm.get('agreements_' + i)?.touched)
        .find((f) => f)
      ;
      return this.registerForm.get(field).valid && siblingsTouched;
    } else {
      return this.registerForm.get(field).valid && this.registerForm.get(field).touched;
    }
  }

  /**
   * Custom behaviour to clear out email error on focus
   */
  clearEmailError() {
    if (this.errorBody && this.errorBody.childErrors && this.errorBody.childErrors.email) {
      delete this.errorBody.childErrors.email;
    }
  }
  hasError(field?: string, code?: string): boolean {
    let retValue = false;
    if (field) {
      if (this.errorBody &&
          this.errorBody.error &&
          this.errorBody.childErrors &&
          this.errorBody.childErrors[field]) {
        if (code) {
          if (this.errorBody.childErrors[field].error === code) {
            retValue = true;
          }
        } else {
          retValue = true;
        }
      }
    } else if (this.genericError) {
      retValue = true;
    }
    return retValue;
  }
  resolveFieldValueForIncludedField(field: string): string | boolean | undefined {
    let retVal: string|undefined;
    if (!!field) {
      if (field.startsWith('agreements_') && this.fields.findIndex((val) => val.key === 'agreements') >= 0) {
        retVal = this.registerForm.get(field).value;
      } else if (field === 'address-region') {
        // the value to submit must be processed and the country part needed for proper localisation removed.
        retVal = this.regionOptions.length > 1 ?
          this.registerForm.get(field.replace('address-', '')).value?.split('-').pop() :
          undefined
        ;
      } else if (this.fields.findIndex((val) => val.key === field) >= 0) {
        retVal = this.registerForm.get(field.replace('address-', '')).value;
      }
    }
    return retVal;
  }

  removeUndefined(obj: any): any {
    const keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i += 1) {
      if (obj[keys[i]] === undefined) {
        delete obj[keys[i]];
      }
    }
    return obj;
  }

  onSubmit() {
    this.errorBody = undefined;
    this.genericError = false;
    const address: Address = this.removeUndefined({
      country: this.resolveFieldValueForIncludedField('address-country'),
      streetAddress: this.resolveFieldValueForIncludedField('address-streetAddress'),
      postalCode: this.resolveFieldValueForIncludedField('address-postalCode'),
      locality: this.resolveFieldValueForIncludedField('address-locality'),
      region: this.resolveFieldValueForIncludedField('address-region'),
      // formatted: this.resolveFieldValueForIncludedField('formattedAddress'),
    });
    const userToRegister: UserForRegisterUser = this.removeUndefined({
      email: this.resolveFieldValueForIncludedField('email'),
      firstName: this.resolveFieldValueForIncludedField('firstName'),
      lastName: this.resolveFieldValueForIncludedField('lastName'),
      password: this.resolveFieldValueForIncludedField('password'),
      phoneNumber: this.resolveFieldValueForIncludedField('phoneNumber'),
      preferredUsername: this.resolveFieldValueForIncludedField('preferredUsername'),
      nickname: this.resolveFieldValueForIncludedField('nickname'),
      professionalTitle: this.resolveFieldValueForIncludedField('professionalTitle'),
      address: address,
    });
    const agr: AgreementAcceptance[]|undefined = this.agreements ? this.agreements.map((c, ind) => {
      return {
          agreementId: c.agreementId,
          accept: this.resolveFieldValueForIncludedField('agreements_' + ind) === true,
          authenticationMethod: AppConstants.AC_AM_ACCEPT_AGREEMENT,
        } as AgreementAcceptance;
      }
    ) : undefined;
    this.logger.debug('Registering...');
    const verificationUri = [];
    if (this.configuration.getProperties().sendEmailValidation && !this.hasInvitationAccept) {
      verificationUri.push(LocationUtil.getOwnDomainURL());
      verificationUri.push(getAppBaseHref());
      verificationUri.push(AppConstants.ROUTE_VALIDATE_EMAIL);
      if (verificationUri[0].indexOf('?') < 0) {
        verificationUri.push('?');
      } else {
        verificationUri.push('&');
      }
      verificationUri.push(AppConstants.QP_EMAIL_VALIDATION_KEY);
      verificationUri.push('={0}');

      verificationUri.push('&');
      verificationUri.push(AppConstants.UI_SESSION_ID);
      verificationUri.push('=');
      verificationUri.push(this.appStateService.getAppState().uiSessionId);

      verificationUri.push(
        LocationUtil.validParamsToQueryString(
          '&',
          [AppConstants.QP_EMAIL_VALIDATION_KEY, AppConstants.QP_EMAIL, AppConstants.QP_LOGIN_HINT, AppConstants.UI_SESSION_ID],
          this.configuration.getProperties().allowedOriginsForNextParam)
      );
    }
    this.appStateService.registerUser(
        userToRegister,
        verificationUri.length > 0,
        verificationUri.length > 0 ? verificationUri.join('') : undefined,
        undefined)
      .subscribe(response => {
        this.logger.debug('Registration completed, response %d', response.status);
        const t: ParamsForRouteNav|string = this.appStateService.getAppState().resolveNextRoute(
          AppConstants.AUHTENTICATION_PROCESS_QUERY_PARAMS)
        ;
        if (typeof t === 'string') {
          this.processingService.show();
          window.location.href = t as string;
        } else if (t) {
          this.router.navigate([t.path], {
            queryParams: t.queryParams,
            fragment: t.fragment,
          });
        }

      }, errorResponse => {
        this.logger.debug('Registration error, response %O', errorResponse);
        const challenges = ResponseUtil.readChallenges(errorResponse).required;
        let emailChallenge: AuthenticationChallenge = null;
        let invitationChallenge: AuthenticationChallenge = null;
        if (challenges && challenges.length) {
          emailChallenge = challenges.find(challenge => AppConstants.AC_AM_VERIFY_EMAIL === challenge.authenticationMethod);
          invitationChallenge = challenges
            .find((challenge) => {
              return AppConstants.AC_AM_INVITATION === challenge.authenticationMethod ||
                AppConstants.AC_AM_INVITATION_ACCEPTED === challenge.authenticationMethod;
            })
          ;
        }
        let agreementChallenges: AcceptAgreementsChallenge[] = null;
        if (challenges && challenges.length) {
            agreementChallenges = challenges
              .filter((c) => c.authenticationMethod === AppConstants.AC_AM_ACCEPT_AGREEMENTS)
              .map((c) => c as AcceptAgreementsChallenge)
            ;
        }
        if (agreementChallenges?.length) {
          this.logger.debug('Registration completed');
          const continueToNext = () => {
            const t = this.appStateService.getAppState().resolveNextRoute(
              [AppConstants.QP_EMAIL, AppConstants.QP_LOGIN_HINT]);
            if (typeof t === 'string') {
              this.processingService.show();
              window.location.href = t as string;
            } else {
              this.router.navigate([t.path], {
                queryParams: t.queryParams,
                fragment: t.fragment,
              });
            }
          };
          if (!agr) {
            continueToNext();
          } else {
            const errorHandler = (response) => {
              this.consentFailed = true;
              this.logger.debug('Accept failed, response %o', response);
              // These are be theorical changes to agreements mid process and good to go as handled later.
              const challenges2 = ResponseUtil.readChallenges(response).required;
              this.logger.debug('Accept updated challenges: %o', challenges2);
              continueToNext();
            };

            const handler = (response) => {
              this.logger.debug('Accept succeeded, response %o', response);
              continueToNext();
            };
            this.consentFailed = false;
            this.appStateService.addAuthentications(agr, false).subscribe(handler, errorHandler);
          }
        } else if (emailChallenge || invitationChallenge) {
          this.logger.debug('Registration completed');
          // Email challenge is ok as it's handled in another component
          if (!this.hasInvitationAccept && this.configuration.getProperties().sendEmailValidation) {
            this.appStateService.getAppState().addNotification(AppConstants.SN_LOGIN_EMAIL_VALIDATION_SENT);
          }
          const t: ParamsForRouteNav|string = this.appStateService.getAppState().resolveNextRoute(
            [AppConstants.QP_EMAIL, AppConstants.QP_LOGIN_HINT]);
          if (typeof t === 'string') {
            this.processingService.show();
            window.location.href = t as string;
          } else if (t) {
            this.router.navigate([t.path], {
              queryParams: {
                ...t.queryParams,
              },
              fragment: t.fragment,
            });
          }
        } else if (errorResponse && errorResponse.error) {
          this.errorBody = errorResponse.error;
          this.genericError = true;
        }
      })
    ;
  }

  showRegion() {
    return this.regionOptions?.length > 1;
  }
  resolveRegionOptions(obj?: Item) {
    const country = this.registerForm.get('country')?.value;
    if (this.regionOptionsCountry !== country) {
      this.regionOptionsCountry = country;
      if (!!country) {
        this.regions.alphabetical(country).subscribe(res => {
          const t = res?.map((n) => ({label: n.name, value: n.code}));
          this.regionOptions = t || [];
          if (obj) {
            this.regionOptions.unshift(obj);
          }
          const region = this.registerForm.get('region');
          if (region?.value &&
            AppConstants.NOT_NULL_PATTERN.test(region?.value) &&
            !this.regionOptions.find((f) => {
              return f.value === region?.value;
            })) {
            region.setValue(null);
          }
        });
      }
    }
  }

  resolveCountryOptions(obj?: Item): Item[] {
    let t = null;
    if (this.countryList) {
      t = [].concat(this.countryList);
      if (obj) {
        t.unshift(obj);
      }
    }
    return t;
  }

  // noinspection JSMethodCanBeStatic
  resolveFormInputWrapperClass(): string {
    return FormInputComponent.WRAPPER_CLASS + ' checkbox-group';
  }

  // noinspection JSMethodCanBeStatic
  resolveFormInputControlWrapperClass(): string {
    return FormInputComponent.CONTROL_WRAPPER_CLASS;
  }

  private subscribeParamsState() {
    this.route.queryParams.subscribe(params => {
      const messageParams = params[AppConstants.QP_MESSAGE];
      const keyEmailParams = params[AppConstants.QP_EMAIL];
      const loginHintParams = params[AppConstants.QP_LOGIN_HINT];
      let email;
      if (keyEmailParams) {
        if (Array.isArray(keyEmailParams)) {
          email = keyEmailParams[0];
        } else {
          email = keyEmailParams;
        }
      }
      if (!email) {
        if (loginHintParams) {
          if (Array.isArray(loginHintParams)) {
            email = loginHintParams[0];
          } else {
            email = loginHintParams;
          }
        }
      }
      if (messageParams) {
        if (Array.isArray(messageParams)) {
          this.message = messageParams[0];
        } else {
          this.message = messageParams;
        }
      } else {
        this.message = undefined;
      }
      this.email = email;
      if (this.email) {
        this.registerForm.get('email').setValue(this.email);
      }
      if (params[AppConstants.QP_INVITATION_TOKEN] &&
          params[AppConstants.QP_REQUIRE_CHALLENGE] === AppConstants.AC_AM_INVITATION_ACCEPTED &&
          this.email
      ) {
        this.hasInvitationAccept = true;
        this.emailAsReadOnly = true;
      }
    });
  }

  private validateRegion(control: AbstractControl): ValidationErrors | null {
    this.resolveRegionOptions({ label: this.resolveSelectRegionLabel(), value: null});
    const regionField = this.registerForm.get('region');
    let retVal;
    if (this.regionOptions?.length > 1) {
      // If there are selectable region options, the rgion is required
      retVal = !!regionField.value && AppConstants.NOT_NULL_PATTERN.test(regionField.value)
        ? null
        : { regionRequired: true };
    } else {
      retVal = null;
    }
    return retVal;
  }
  private validateConfirmPassword(control: AbstractControl): ValidationErrors | null {
    const pwField = this.registerForm.get('password');
    const cpwField = this.registerForm.get('confirmPassword');
    return cpwField.value === pwField.value
      ? null
      : { 'mustMatch': true };
  }
  public resolveSelectCountryLabel(): any {
    return this.translate.instant(_('register.country.select-option'));
  }
  public resolveSelectRegionLabel(): any {
    return this.translate.instant(_('register.region.select-option'));
  }

  public resolveWindowTitlePart(): Observable<string|undefined> {
    return this.translate.get('register.window-title');
  }
}
