/**
 * 10Duke End User Identity API
 * Authenticate user and manage authenticated sessions
 *
 * OpenAPI spec version: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiError } from '../model/apiError';
import { RecoveryEmailAddress } from '../model/recoveryEmailAddress';
import { RecoveryEmailAddresses } from '../model/recoveryEmailAddresses';
import { SetEmailAsPrimaryRequest } from '../model/setEmailAsPrimaryRequest';
import { VerifyEmailRequest } from '../model/verifyEmailRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class RecoveryEmailService {

    protected basePath = 'http://localhost:8080/api/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Creates a new recovery email
     * Creates a new recovery email address for the user
     * @param body Describes the recovery email address to create
     * @param verifyEmail Indicates if email verification process is started for the created recovery email address. If set to \&quot;true\&quot;, a message is sent to the email address, and user can verify the email address by following instructions given in the message.
     * @param verificationUri URI where user can navigate for completing email verification. Value of this parameter must contain format argument holder {0} for the email verification code.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createRecoveryEmail(body: RecoveryEmailAddress, verifyEmail?: boolean, verificationUri?: string, observe?: 'body', reportProgress?: boolean): Observable<RecoveryEmailAddress>;
    public createRecoveryEmail(body: RecoveryEmailAddress, verifyEmail?: boolean, verificationUri?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RecoveryEmailAddress>>;
    public createRecoveryEmail(body: RecoveryEmailAddress, verifyEmail?: boolean, verificationUri?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RecoveryEmailAddress>>;
    public createRecoveryEmail(body: RecoveryEmailAddress, verifyEmail?: boolean, verificationUri?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createRecoveryEmail.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (verifyEmail !== undefined && verifyEmail !== null) {
            queryParameters = queryParameters.set('verifyEmail', <any>verifyEmail);
        }
        if (verificationUri !== undefined && verificationUri !== null) {
            queryParameters = queryParameters.set('verificationUri', <any>verificationUri);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<RecoveryEmailAddress>(`${this.basePath}/recoveryEmails`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a recovery email address
     * Removes details of a recovery email address configured for the user
     * @param recoveryEmailId Id of the recovery email to get
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteRecoveryEmail(recoveryEmailId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteRecoveryEmail(recoveryEmailId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteRecoveryEmail(recoveryEmailId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteRecoveryEmail(recoveryEmailId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (recoveryEmailId === null || recoveryEmailId === undefined) {
            throw new Error('Required parameter recoveryEmailId was null or undefined when calling deleteRecoveryEmail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/recoveryEmails/${encodeURIComponent(String(recoveryEmailId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a recovery email address
     * Gets a single recovery email address configured for the user
     * @param recoveryEmailId Id of the recovery email to get
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRecoveryEmail(recoveryEmailId: string, observe?: 'body', reportProgress?: boolean): Observable<RecoveryEmailAddress>;
    public getRecoveryEmail(recoveryEmailId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RecoveryEmailAddress>>;
    public getRecoveryEmail(recoveryEmailId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RecoveryEmailAddress>>;
    public getRecoveryEmail(recoveryEmailId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (recoveryEmailId === null || recoveryEmailId === undefined) {
            throw new Error('Required parameter recoveryEmailId was null or undefined when calling getRecoveryEmail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RecoveryEmailAddress>(`${this.basePath}/recoveryEmails/${encodeURIComponent(String(recoveryEmailId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lists recovery email addresses
     * Gets list of recovery email addresses configured for the user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listRecoveryEmails(observe?: 'body', reportProgress?: boolean): Observable<RecoveryEmailAddresses>;
    public listRecoveryEmails(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RecoveryEmailAddresses>>;
    public listRecoveryEmails(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RecoveryEmailAddresses>>;
    public listRecoveryEmails(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<RecoveryEmailAddresses>(`${this.basePath}/recoveryEmails`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Starts recovery email verification process
     * Sends email to recovery email address for verifying the address. Verification code contained by the email message can be used then for adding authentication with the \&quot;addAuthentications\&quot; operation.
     * @param body Describes the email verification request
     * @param recoveryEmailId Id of the recovery email to verify
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendRecoveryEmailVerification(body: VerifyEmailRequest, recoveryEmailId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public sendRecoveryEmailVerification(body: VerifyEmailRequest, recoveryEmailId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public sendRecoveryEmailVerification(body: VerifyEmailRequest, recoveryEmailId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public sendRecoveryEmailVerification(body: VerifyEmailRequest, recoveryEmailId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling sendRecoveryEmailVerification.');
        }

        if (recoveryEmailId === null || recoveryEmailId === undefined) {
            throw new Error('Required parameter recoveryEmailId was null or undefined when calling sendRecoveryEmailVerification.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/recoveryEmails/${encodeURIComponent(String(recoveryEmailId))}/startVerification`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sets a recovery email as the primary email
     * Sets a recovery email address as the primary email address that is used as the login name
     * @param body Describes a request to set a recovery email as the primary email
     * @param recoveryEmailId Id of the recovery email to set as the primary email
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setRecoveryEmailAsPrimary(body: SetEmailAsPrimaryRequest, recoveryEmailId: string, observe?: 'body', reportProgress?: boolean): Observable<RecoveryEmailAddresses>;
    public setRecoveryEmailAsPrimary(body: SetEmailAsPrimaryRequest, recoveryEmailId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RecoveryEmailAddresses>>;
    public setRecoveryEmailAsPrimary(body: SetEmailAsPrimaryRequest, recoveryEmailId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RecoveryEmailAddresses>>;
    public setRecoveryEmailAsPrimary(body: SetEmailAsPrimaryRequest, recoveryEmailId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling setRecoveryEmailAsPrimary.');
        }

        if (recoveryEmailId === null || recoveryEmailId === undefined) {
            throw new Error('Required parameter recoveryEmailId was null or undefined when calling setRecoveryEmailAsPrimary.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<RecoveryEmailAddresses>(`${this.basePath}/recoveryEmails/${encodeURIComponent(String(recoveryEmailId))}/setAsPrimaryEmail`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates recovery email address details
     * Updates details of the given recovery email address configured for the user
     * @param body Describes the updated recovery email address details
     * @param recoveryEmailId Id of the recovery email to get
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateRecoveryEmail(body: RecoveryEmailAddress, recoveryEmailId: string, observe?: 'body', reportProgress?: boolean): Observable<RecoveryEmailAddress>;
    public updateRecoveryEmail(body: RecoveryEmailAddress, recoveryEmailId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RecoveryEmailAddress>>;
    public updateRecoveryEmail(body: RecoveryEmailAddress, recoveryEmailId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RecoveryEmailAddress>>;
    public updateRecoveryEmail(body: RecoveryEmailAddress, recoveryEmailId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateRecoveryEmail.');
        }

        if (recoveryEmailId === null || recoveryEmailId === undefined) {
            throw new Error('Required parameter recoveryEmailId was null or undefined when calling updateRecoveryEmail.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<RecoveryEmailAddress>(`${this.basePath}/recoveryEmails/${encodeURIComponent(String(recoveryEmailId))}`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
