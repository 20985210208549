/**
 * 10Duke End User Identity API
 * Authenticate user and manage authenticated sessions
 *
 * OpenAPI spec version: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiError } from '../model/apiError';
import { Invitation } from '../model/invitation';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class InvitationService {

    protected basePath = 'http://localhost:8080/api/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Accepts an invitation with an invitation token
     * Accepts invitation identified by invitation token sent to the invitee
     * @param token 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public acceptInvitation(token: string, email: string, observe?: 'body', reportProgress?: boolean): Observable<Invitation>;
    public acceptInvitation(token: string, email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Invitation>>;
    public acceptInvitation(token: string, email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Invitation>>;
    public acceptInvitation(token: string, email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling acceptInvitation.');
        }

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling acceptInvitation.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | any; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (token !== undefined) {
            formParams = formParams.append('token', <any>token) || formParams;
        }
        if (email !== undefined) {
            formParams = formParams.append('email', <any>email) || formParams;
        }

        return this.httpClient.put<Invitation>(`${this.basePath}/invitations/accept`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Accepts an invitation with an invitation id
     * Accepts invitation identified by the given invitation id. This is only allowed if invitation recipient email matches a verified email of the authenticated user.
     * @param invitationId Unique id of invitation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public acceptInvitationById(invitationId: string, observe?: 'body', reportProgress?: boolean): Observable<Invitation>;
    public acceptInvitationById(invitationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Invitation>>;
    public acceptInvitationById(invitationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Invitation>>;
    public acceptInvitationById(invitationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invitationId === null || invitationId === undefined) {
            throw new Error('Required parameter invitationId was null or undefined when calling acceptInvitationById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<Invitation>(`${this.basePath}/invitations/${encodeURIComponent(String(invitationId))}/accept`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Declines an invitation with an invitation token
     * Declines invitation identified by invitation token sent to the invitee
     * @param token 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public declineInvitation(token: string, email: string, observe?: 'body', reportProgress?: boolean): Observable<Invitation>;
    public declineInvitation(token: string, email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Invitation>>;
    public declineInvitation(token: string, email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Invitation>>;
    public declineInvitation(token: string, email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling declineInvitation.');
        }

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling declineInvitation.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | any; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (token !== undefined) {
            formParams = formParams.append('token', <any>token) || formParams;
        }
        if (email !== undefined) {
            formParams = formParams.append('email', <any>email) || formParams;
        }

        return this.httpClient.put<Invitation>(`${this.basePath}/invitations/decline`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Declines an invitation with an invitation id
     * Declines invitation identified by the given invitation id. This is only allowed if invitation recipient email matches a verified email of the authenticated user.
     * @param invitationId Unique id of invitation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public declineInvitationById(invitationId: string, observe?: 'body', reportProgress?: boolean): Observable<Invitation>;
    public declineInvitationById(invitationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Invitation>>;
    public declineInvitationById(invitationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Invitation>>;
    public declineInvitationById(invitationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invitationId === null || invitationId === undefined) {
            throw new Error('Required parameter invitationId was null or undefined when calling declineInvitationById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<Invitation>(`${this.basePath}/invitations/${encodeURIComponent(String(invitationId))}/decline`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reads invitation by invitation id
     * Reads details of an invitation by invitation id. This is only allowed if invitation recipient email matches a verified email of the authenticated user.
     * @param invitationId Unique id of invitation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInvitationById(invitationId: string, observe?: 'body', reportProgress?: boolean): Observable<Invitation>;
    public getInvitationById(invitationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Invitation>>;
    public getInvitationById(invitationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Invitation>>;
    public getInvitationById(invitationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (invitationId === null || invitationId === undefined) {
            throw new Error('Required parameter invitationId was null or undefined when calling getInvitationById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Invitation>(`${this.basePath}/invitations/${encodeURIComponent(String(invitationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reads invitation by invitation token
     * Reads details of an invitation by invitation token sent to the invitee
     * @param token 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInvitationByToken(token: string, email: string, observe?: 'body', reportProgress?: boolean): Observable<Invitation>;
    public getInvitationByToken(token: string, email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Invitation>>;
    public getInvitationByToken(token: string, email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Invitation>>;
    public getInvitationByToken(token: string, email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling getInvitationByToken.');
        }

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling getInvitationByToken.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | any; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (token !== undefined) {
            formParams = formParams.append('token', <any>token) || formParams;
        }
        if (email !== undefined) {
            formParams = formParams.append('email', <any>email) || formParams;
        }

        return this.httpClient.post<Invitation>(`${this.basePath}/invitations`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
