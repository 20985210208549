
<ng-template #qrCodeHolder>
  <app-duke-alert
    type="info"
    alertClass="mb-0"
    *ngIf="qrCodeImage || secretKey"
  >
    <div class="clearfix" [formGroup]="activateTotpForm">
      <div *ngIf="qrCodeImage" class="float-sm-right ml-auto mr-auto mb-3 ml-sm-3 qr-code-holder">
        <img [src]="this.qrCodeImage" title="" alt="" class="img-fluid mb-2"/>
        <div class="form-group row">
          <label
            for="secretKey"
            class="col-12 col-form-label"
          >{{'configure-totp.configuration.secretKey.label'|translate}}</label>
          <div class="col-12">
            <input
              id="secretKey"
              name="secretKey"
              type="text"
              formControlName="secretKey"
              class="form-control"
              readonly
            />
            <div
              app-form-input-feedback
              type="info"
            >{{'configure-totp.configuration.secretKey.info'|translate}}</div>
          </div>
        </div>
      </div>
      <h2 *ngIf="false" class="alert-heading h5">{{'configure-totp.configuration.title'|translate}}</h2>
      <p>
        {{'configure-totp.configuration.info1'|translate}}
      </p>
      <p *ngIf="!totpIsActive">
        {{'configure-totp.configuration.info2'|translate}}
      </p>
      <p class="mb-0 custom-totp-link" [innerHTML]="('configure-totp.configuration.custom-totp-link'|translate)"></p>
    </div>
  </app-duke-alert>
  <hr />
</ng-template>
<div
  class="card standalone fade configure-totp-component"
  [ngClass]="{
    'show': isReady()
  }"
>
  <h1
    class="card-header text-center"
  >
    {{'configure-totp.title'|translate}}
  </h1>
  <form
    [formGroup]="activateTotpForm"
    (ngSubmit)="submit()"
    *ngIf="totpIsActive === false"
  >
    <div class="card-body">
      <ng-container [ngTemplateOutlet]="qrCodeHolder"></ng-container>
      <input
        type="text"
        name="userName"
        autocomplete="username"
        hidden
        [value]="getProfile() ? getProfile().email : ''"
      />

      <div
        app-form-input
        field="code1"
        label="{{'configure-totp.activate.code1.label'|translate}}"
        type="text"
        autocomplete="one-time-code"
        [isInvalid]="isFieldInvalid(activateTotpForm.get('code1'))"
        [isValid]="isFieldValid(activateTotpForm.get('code1'))"
      >
        <div
          app-form-input-feedback
          type="invalid"
          *ngIf="isFieldInvalid(activateTotpForm.get('code1'))"
        >{{'configure-totp.activate.code1.error-message.required-or-invalid'|translate}}</div>
      </div>

      <div
        app-form-input
        field="code2"
        label="{{'configure-totp.activate.code2.label'|translate}}"
        type="text"
        autocomplete="one-time-code"
        [isInvalid]="isFieldInvalid(activateTotpForm.get('code2'))"
        [isValid]="isFieldValid(activateTotpForm.get('code2'))"
      >
        <div
          app-form-input-feedback
          type="invalid"
          *ngIf="isFieldInvalid(activateTotpForm.get('code2'))"
        >{{'configure-totp.activate.code2.error-message.required-or-invalid'|translate}}</div>
      </div>
      <app-duke-alert
        data-test-submit-error-message
        type="danger"
        *ngIf="hasError()"
        alertClass="mb-0"
        dismissible="true"
        (open)="alertHandler.onOpen($event);"
        (close)="alertHandler.onClose($event); submitFailed = false;"
      >
        <p class="mb-0">
          {{'configure-totp.activate.submit-error.message'|translate}}
        </p>
      </app-duke-alert>
    </div>
    <div class="card-footer">
      <div class="d-flex align-items-center flex-row-reverse">
        <button
          data-test-submit-btn
          class="btn btn-success"
          type="submit"
          [disabled]="!activateTotpForm.valid"
        >
          {{'configure-totp.activate.change-button.label'|translate}}
        </button>
        <a
          data-test-cancel-btn
          [routerLink]="getAppConstant('PATH_EDIT_PROFILE')"
          fragment="login"
          queryParamsHandling="merge"
          class="btn btn-secondary order-3 mr-auto"
        >
          {{'configure-totp.activate.cancel-button.label'|translate}}
        </a>
        <span
          class="text-danger order-2 mr-2 text-right"
          *ngIf="hasError()"
          [@cardFooterFeedbackTransition]
          [@.disabled]="disableAnimations()"
        >
          <span class="icon fa fa-fw fa-exclamation-triangle"></span>
          <span>
            {{'configure-totp.activate.submit-error.notification'|translate}}
          </span>
        </span>
      </div>
    </div>
  </form>

  <form
    [formGroup]="deactivateTotpForm"
    (ngSubmit)="submit()"
    *ngIf="totpIsActive === true"
  >
    <div class="card-body">
      <ng-container [ngTemplateOutlet]="qrCodeHolder"></ng-container>
      <p>{{'configure-totp.deactivate.info'|translate}}</p>

      <div
        app-form-input
        field="code"
        label="{{'configure-totp.deactivate.code.label'|translate}}"
        type="text"
        autocomplete="one-time-code"
        [isInvalid]="isFieldInvalid(deactivateTotpForm.get('code'))"
        [isValid]="isFieldValid(deactivateTotpForm.get('code'))"
      >
        <div
          app-form-input-feedback
          type="invalid"
          *ngIf="isFieldInvalid(deactivateTotpForm.get('code'))"
        >{{'configure-totp.deactivate.code.error-message.required-or-invalid'|translate}}</div>
      </div>
      <app-duke-alert
        type="danger"
        *ngIf="hasError()"
        alertClass="mb-0"
        dismissible="true"
        (open)="alertHandler.onOpen($event);"
        (close)="alertHandler.onClose($event); submitFailed = false;"
      >
        <p class="mb-0">
          {{'configure-totp.deactivate.submit-error.message'|translate}}
        </p>
      </app-duke-alert>
    </div>
    <div class="card-footer">
      <div class="d-flex align-items-center flex-row-reverse">
        <button
          class="btn btn-danger"
          type="submit"
          data-test-submit-btn
          [disabled]="!deactivateTotpForm.valid"
        >
          {{'configure-totp.deactivate.change-button.label'|translate}}
        </button>
        <a
          [routerLink]="getAppConstant('PATH_EDIT_PROFILE')"
          fragment="login"
          queryParamsHandling="merge"
          class="btn btn-secondary order-3 mr-auto"
        >
          {{'configure-totp.deactivate.cancel-button.label'|translate}}
        </a>
        <span
          class="text-danger order-2 mr-2 text-right"
          *ngIf="hasError()"
          [@cardFooterFeedbackTransition]
          [@.disabled]="disableAnimations()"
        >
          <span class="icon fa fa-fw fa-exclamation-triangle"></span>
          <span>
            {{'configure-totp.deactivate.submit-error.notification'|translate}}
          </span>
        </span>
      </div>
    </div>
  </form>
</div>
