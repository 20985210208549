import {HttpErrorResponse, HttpResponseBase} from '@angular/common/http';
import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, FormGroupName, ValidationErrors, Validators} from '@angular/forms';
import {ActivatedRoute, NavigationEnd, Router,} from '@angular/router';
import {Observable, Subscription, timer} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Logger} from 'src/util/logger';
import ResponseUtil from 'src/util/responseUtil';
import {PasswordService} from '../../gen/api/password.service';
import {AuthenticationChallenge} from '../../gen/model/authenticationChallenge';
import {AuthenticationFlow} from '../../gen/model/authenticationFlow';
import {AuthenticationFlows} from '../../gen/model/authenticationFlows';
import {Credentials} from '../../gen/model/credentials';
import {SendEmailForResetPasswordRequest} from '../../gen/model/sendEmailForResetPasswordRequest';
import {TimeBasedOneTimePasswordChallenge} from '../../gen/model/timeBasedOneTimePasswordChallenge';
import {TimeBasedOneTimePasswordCredential} from '../../gen/model/timeBasedOneTimePasswordCredential';
import {UserNameAndPasswordChallenge} from '../../gen/model/userNameAndPasswordChallenge';
import {UserNameAndPasswordCredential} from '../../gen/model/userNameAndPasswordCredential';
import {UserNameAndPasswordCredentialForResetPassword} from '../../gen/model/userNameAndPasswordCredentialForResetPassword';
import {AppConstants} from '../AppConstants';
import {LoginBaseComponent} from '../login-base.component';
import {StandaloneComponent} from '../standalone.component';
import {ParamsService} from '../params.service';
import {AlertHandler} from '../no-concurrent-alerts-handler';
import {animate, AnimationBuilder, AnimationFactory, query, style} from '@angular/animations';
import {
  cardFooterFeedbackTransition,
  createTabSlideDownAndFadeInTransitionSteps,
  createTabSlideUpAndFadeInTransitionSteps
} from '../animations';
import {DelegatedAuthenticationChallenge} from '../../gen';
import {getApiBasePath} from '../../api/real-api/api-base-path';
import LocationUtil, {getAppBaseHref, UrlParams} from '../../util/locationUtil';
import {TranslateService} from '@ngx-translate/core';
import {ConfigurationService} from '../ui-configuration/configuration-service';
import {AppStateService, ParamsForRouteNav} from '../app-state.service';
import {FormInputComponent} from '../form-input/form-input.component';
import {ProcessingIndicatorService} from '../processing-indicator.service';

const cloneDeep = require('lodash.clonedeep');

interface LoginComponentParams {
  username: string;
  resetKey: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [
    './login.component.scss',
  ],
  viewProviders: [
    { provide: FormGroupName, useFactory: () => null },
  ],
  encapsulation: ViewEncapsulation.None,
  animations: [
    cardFooterFeedbackTransition
  ],
})
export class LoginComponent extends LoginBaseComponent implements OnInit, OnDestroy {

  private static INITIAL_SEND_TIMEOUT = 2;
  private static RESEND_TIMEOUT = 5;
  private static ENABLED_PWD_FLOW = {
    key: 'enabledPassword',
    description: 'Requires authentication by email address and password',
    challenges: [
      {
        required: true,
        authenticationMethod: 'password',
        factor: 'knowledge',
      }
    ]
  };
  private enabledPWDFlow: AuthenticationFlow;
  // Commented out for now as while fully functional and valid concept, not actually needed
  // private requiredChallengesInActiveFlow: Array<AuthenticationChallenge>;
  private activeFlowKey: string;
  private navigationEndSubscription: Subscription;

  private currentFlowStep: AuthenticationChallenge;

  private invalidPasswordError: UserNameAndPasswordChallenge;
  private invalidCodeError: boolean;
  private invalidTotpError: TimeBasedOneTimePasswordChallenge;
  private tooManyAttemptsError: boolean;
  private tooManyAttemptsTimeoutCounter: any;
  private tooManyAttemptsTimeout: number;
  private genericError: boolean;
  private destroyed = false;
  sendEmailTimeout: number;
  private sendEmailTimeoutCounter: any;
  private loginBeforeNextDismissed = false;

  private totpPattern: RegExp = /^([\s]*\d[\s]*){6}$/;

  private readonly totpValidators = [Validators.required, Validators.pattern(this.totpPattern)];
  passwordStepKey = AppConstants.AC_AM_PASSWORD;
  totpStepKey = AppConstants.AC_AM_TOTP;
  resetKeyParam: string|undefined;
  userNameAsFixed = false;
  emailsent = false;
  emailfailed = false;
  showRememberMe = false;
  externalRedirect = true;
  allowRegister = true;
  processingDelegated = false;
  showCodeInput: boolean;
  showPasswordInputsWithoutCode: boolean;
  secondaryFlowsInFooter: boolean;

  defaultForm: FormGroup;
  errorForm: FormGroup;

  passwordForm = new FormGroup({
    password: new FormControl('', [Validators.required]),
  });
  resetPasswordForm: FormGroup;

  totpForm = new FormGroup({
    totp: new FormControl('', this.totpValidators),
  });

  showServerError: string|undefined;

  @ViewChild('userName', { read: ElementRef, static: true }) userNameField: ElementRef;
  @ViewChild('password', { read: ElementRef }) passwordField: ElementRef;
  @ViewChild('passwordForResetPassword', { read: ElementRef }) passwordFieldForResetPassword: ElementRef;
  @ViewChild('codeFieldForResetPassword', { read: ElementRef }) codeFieldForResetPassword: ElementRef;
  @ViewChild('totp', { read: ElementRef, static: true }) totpField: ElementRef;
  @ViewChild('tabHolder') tabHolder: ElementRef;
  @ViewChild('usernameTab') usernameTab: ElementRef;
  @ViewChild('passwordTab') passwordTab: ElementRef;
  @ViewChild('totpTab') totpTab: ElementRef;
  @ViewChild('cardHeader') cardHeader: ElementRef;
  @ViewChild('restartBtn') restartBtn: ElementRef;

  constructor(
    private animationBuilder: AnimationBuilder,
    @Inject('PasswordApi') private passwordApi: PasswordService,
    private appStateService: AppStateService,
    private paramsService: ParamsService,
    private translate: TranslateService,
    route: ActivatedRoute,
    router: Router,
    private alertHandler: AlertHandler,
    private processingService: ProcessingIndicatorService,
    private logger: Logger,
    hostElement: ElementRef,
    configuration: ConfigurationService
  ) {

    super(route, router, hostElement, configuration);
    this.focusSelectorPrefix = '.tab-content .active .form-group  ';
    this.errorForm = new FormGroup({
      continue: new FormControl(false, {validators: [Validators.requiredTrue], updateOn: 'change'}),
    });
    this.resetPasswordForm = new FormGroup({
      passwordForResetPassword: new FormControl(
        '', [Validators.required, Validators.pattern(configuration.getProperties().passwordValidation)]),
      confirmPassword: new FormControl('', [Validators.required]),
      code: new FormControl('', [Validators.required]),
    });

    this.validateConfirmPassword = this.validateConfirmPassword.bind(this);
    this.resetPasswordForm.setValidators([this.validateConfirmPassword]);
    this.showCodeInput = this.configuration.getProperties().showResetPasswordCodeInput;
    this.showPasswordInputsWithoutCode = this.configuration.getProperties().showResetPasswordFieldsWithoutCode;
    this.secondaryFlowsInFooter = this.configuration.getProperties().loginSecondaryFlowsInFooter;
  }

  ngOnInit() {
    this.init();
    this.sendEmailTimeout = LoginComponent.INITIAL_SEND_TIMEOUT;
    this.sendEmailTimeoutCounter = timer(1000, 1000).subscribe(() => {
      this.sendEmailTimeout--;
      if (this.sendEmailTimeout <= 0) {
        this.sendEmailTimeout = undefined;
        this.sendEmailTimeoutCounter.unsubscribe();
        this.sendEmailTimeoutCounter = undefined;
      }
    });
  }

  ngOnDestroy() {
    this.destroyed = true;
    if (this.navigationEndSubscription) {
      this.navigationEndSubscription.unsubscribe();
      this.navigationEndSubscription = undefined;
    }
    if (this.sendEmailTimeoutCounter) {
      this.sendEmailTimeout = undefined;
      this.sendEmailTimeoutCounter.unsubscribe();
      this.sendEmailTimeoutCounter = undefined;
    }
  }
  dismissLoginBeforeNext() {
    this.loginBeforeNextDismissed = true;
  }
  continueFromInvalidReset() {
    const nav: ParamsForRouteNav|string|undefined = this.appStateService.getAppState().resolveNextRoute(
       ['*']);
    if (typeof nav === 'string') {
      this.processingService.show();
      window.location.href = nav as string;
    } else if (nav) {
      this.router.navigate([nav.path], {
        queryParams: { ...nav.queryParams },
        fragment: nav.fragment,
      });
    }
  }
  showCancelResetPassword(): boolean {
    const nav: ParamsForRouteNav|string|undefined = this.appStateService.getAppState().resolveNextRoute(
      ['*']);
    if (typeof nav !== 'string' &&
      nav &&
      nav.path === AppConstants.PATH_PROFILE &&
      (!nav.fragment || nav.fragment === '')
    ) {
      return false;
    } else {
      return true;
    }
  }
  cancelResetPassword() {
    if (!this.appStateService.getAppState().getAuthenticationState().hasSession()) {
      // keep params, except the ones not needed elsewhere
      this.router.navigate([AppConstants.PATH_LOGIN], {
        relativeTo: this.route,
        queryParams: {
          [AppConstants.QP_RESET_PASSWORD_KEY]: undefined,
        },
        queryParamsHandling: 'merge'
      });
    } else if (!this.appStateService.getAppState().getAuthenticationState().isFullyAuthenticated()) {
      this.router.navigate([AppConstants.PATH_LOGOUT], {
        relativeTo: this.route,
      });
    } else {
      this.router.navigate([AppConstants.PATH_PROFILE], {
        relativeTo: this.route,
      });
    }
  }

  protected init() {
    super.init();
    this.defaultForm = new FormGroup({
      userName: new FormControl('', [Validators.required, Validators.email]),
      rememberMe: new FormControl(false),
    });
    // app.component takes care of the initial loading, so we can just copy in the state from the service
    this.readParams().then(v => {
      if (!this.isResetPassword() &&
        this.appStateService.getAppState().getAuthenticationState().getExistingAuthentications() &&
        this.appStateService.getAppState().getAuthenticationState().getExistingAuthentications().length) {
        this.continueFromChallenge();
      } else if (v.username && this.defaultForm.get('userName').valid) {
        this.next();
      } else {
        this.first(undefined, true);
      }
    });
    // looks like this is not activated when this is the initial route. The event has likely already passed when the listener is added
    this.navigationEndSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd && e.url.indexOf(AppConstants.PATH_LOGIN) === 0) {
        const handler = () => {
          const tab: ElementRef = this.getCurrentFlowStepTab();
          this.reset();
          this.readParams().then(v => {
            if (v.username && this.defaultForm.get('userName').valid) {
              this.next(tab);
            } else {
              this.first(tab);
            }
          });
        };
        if (this.appStateService.getAppState().getAuthenticationState().hasSession()) {
          this.logger.debug('Login component init with session, should this happen?');
        } else {
          handler();
        }
      }
    });
  }
  hasSession() {
    return this.appStateService.getAppState().getAuthenticationState().hasSession();
  }
  continueFromChallenge() {
    const completedChallenges = this.appStateService.getAppState().getAuthenticationState().getExistingAuthentications();
    for (let i = 0; i < completedChallenges.length; i += 1) {
      const challenge: AuthenticationChallenge = completedChallenges[i];
      const un: string = this.defaultForm.get('userName').value;
      if (!un &&
          challenge.authenticationMethod === AppConstants.AC_AM_PASSWORD &&
          (challenge as UserNameAndPasswordChallenge).userName
      ) {
        this.defaultForm.get('userName').setValue((challenge as UserNameAndPasswordChallenge).userName);
      }
      const flow: AuthenticationFlow = this.findAuthenticationFlowStartingWithChallenge(
        this.appStateService.getAppState().getAuthenticationState().getAuthenticationFlows(), challenge)
      ;
      if (flow) {
        this.activeFlowKey = flow.key;
        let c: AuthenticationChallenge = flow.challenges[0];
        let lastCompleted: AuthenticationChallenge = c;
        while (c && completedChallenges.findIndex((cc: AuthenticationChallenge) =>
          (cc.authenticationMethod !== AppConstants.AC_AM_DELEGATED && cc.authenticationMethod === c.authenticationMethod) ||
          (cc.authenticationMethod === AppConstants.AC_AM_DELEGATED &&
            (cc as DelegatedAuthenticationChallenge).applicationId ===
            (c as DelegatedAuthenticationChallenge).applicationId
          )) >= 0) {
          lastCompleted = c;
          c = c.challenges && c.challenges.length ? c.challenges[0] : null;
        }
        this.currentFlowStep = lastCompleted;
        break;
      }
    }
    this.next();
  }

  isResetPassword(): boolean {
    let retValue = false;
    if (this.router.isActive(AppConstants.PATH_RESET_PASSWORD, false)) {
      retValue = true;
    }
    return retValue;
  }

  showInvalidCodeAlert(): boolean {
    return this.invalidCodeError;
  }

  showInvalidPasswordAlert(): boolean {
    return this.invalidPasswordError !== undefined;
  }

  showInvalidTotpAlert(): boolean {
    return this.invalidTotpError !== undefined;
  }

  showSignInFailed(): boolean {
    return this.genericError === true;
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return this.hasError(field) || this.isInputInvalid(field);
  }

  isInputInvalid(field: AbstractControl): boolean {
    if (field === this.resetPasswordForm.get('confirmPassword')) {
      return (field.invalid ||
        (this.resetPasswordForm.invalid && this.resetPasswordForm.errors && this.resetPasswordForm.errors.mustMatch)) && field.touched;

    } else {
      return field.invalid && field.touched;
    }
  }

  // noinspection JSMethodCanBeStatic
  resolveFormInputControlWrapperClass(): string {
    return FormInputComponent.CONTROL_WRAPPER_CLASS;
  }
  // noinspection JSMethodCanBeStatic
  resolveFormInputWrapperClass(): string {
    return FormInputComponent.WRAPPER_CLASS;
  }

  isFieldValid(field: AbstractControl): boolean {
    return !this.hasError(field) && this.isInputValid(field);
  }

  isInputValid(field: AbstractControl): boolean {
    if (field === this.resetPasswordForm.get('confirmPassword')) {
      return field.valid
        && (this.resetPasswordForm.valid || !this.resetPasswordForm.errors || !this.resetPasswordForm.errors.mustMatch)
        && field.touched;
    } else {
      return field.valid && field.touched;
    }
  }

  hasError(field?: AbstractControl, code?: string): boolean {
    let retValue = false;
    if (field) {
      if ((field === this.defaultForm.get('userName') ||
        field === this.passwordForm.get('password') ||
        field === this.resetPasswordForm.get('passwordForResetPassword')) && this.showInvalidPasswordAlert()) {
        retValue = true;
      } else if (field === this.totpForm.get('totp') && this.showInvalidTotpAlert()) {
        retValue = true;
      } else if (field === this.resetPasswordForm.get('code') && this.showInvalidCodeAlert()) {
        retValue = true;
      }
    } else if (this.showSignInFailed()) {
      retValue = true;
    }
    return retValue;
  }
  isValidSecondaryFlow(flow: AuthenticationFlow): boolean {
    let retVal = false;
    if (flow &&
        !flow.disabled &&
        flow.key !== this.activeFlowKey &&
        flow.key !== this.findFlowKeyByAuthenticationMethod(AppConstants.AC_AM_PASSWORD) &&
        flow.challenges &&
        flow.challenges.length) {
      retVal = true;
    }
    return retVal;
  }
  getFlowButtonLabel(flow: AuthenticationFlow): string {
    let retVal = 'unknown';
    try {
      if ((flow.challenges[0] as DelegatedAuthenticationChallenge).applicationName) {
        const icon = this.getFlowIcon(flow);
        retVal = icon + (flow.challenges[0] as DelegatedAuthenticationChallenge).applicationName;
      }
    } catch (e) {}
    return retVal;
  }
  // noinspection JSMethodCanBeStatic
  getFlowIcon(flow: AuthenticationFlow): string {
    let retVal = '';
    try {
      // TODO: refactor to use configured image and application key, once they've been merged into the DelegatedAuthenticationChallenge
      if ((flow.challenges[0] as DelegatedAuthenticationChallenge).applicationName) {
        if ((flow.challenges[0] as DelegatedAuthenticationChallenge).applicationName === 'Facebook') {
          retVal = '<span class="fab fa-fw fa-facebook-f"></span>&nbsp;';
        } else if ((flow.challenges[0] as DelegatedAuthenticationChallenge).applicationName === 'Google') {
          retVal = '<span class="fab fa-fw fa-google"></span>&nbsp;';
        } else if ((flow.challenges[0] as DelegatedAuthenticationChallenge).applicationName === 'ADFS') {
          retVal = '<span class="fab fa-fw fa-windows"></span>&nbsp;';
        }
      }
    } catch (e) {}
    return retVal;
  }
  stripErrorParamFromCurrentUrl(): string {
    const currentUrl = window.location.href.split('?');
    let strippedUrl = currentUrl[0];
    if (currentUrl.length > 1) {
      const params = currentUrl[1].split('&').filter((f) => !f.startsWith(AppConstants.QP_ERROR + '='));
      if (params.length > 0) {
        strippedUrl += '?' + params.join('&');
      }
    }
    return strippedUrl;
  }
  chooseFlow(flow: AuthenticationFlow) {
    this.processingDelegated = true;
    if (this.defaultForm && this.defaultForm.get('userName')) {
      this.defaultForm.get('userName').markAsUntouched();
    }
    if (this.passwordForm && this.passwordForm.get('password')) {
      this.passwordForm.get('password').markAsUntouched();
    }
    if (this.totpForm && this.totpForm.get('totp')) {
      this.totpForm.get('totp').markAsUntouched();
    }
    const ch = () => {
      if (flow && flow.challenges && flow.challenges.length) {
        if (flow.challenges[0].authenticationMethod === AppConstants.AC_AM_DELEGATED) {
          const challenge: DelegatedAuthenticationChallenge = flow.challenges[0] as DelegatedAuthenticationChallenge;
          const strippedUrl = this.stripErrorParamFromCurrentUrl();
          const email = this.defaultForm?.get('userName')?.value;
          const t = [
            getApiBasePath(),
            '/sessions/externalIdentityProviders/',
            challenge.applicationId,
            '/signOn?',
            AppConstants.QP_NEXT,
            '=',
            encodeURIComponent(strippedUrl),
          ];
          if (email) {
            t.push('&');
            t.push(AppConstants.QP_LOGIN_HINT);
            t.push('=');
            t.push(encodeURIComponent(email));
          }
          this.processingService.show();
          window.location.href = t.join('');
        } else {
          // TODO: support oither types
          this.processingDelegated = false;
          throw new Error('LoginComponent chooseFlow: only delegated flows are supported as secondary flows');
        }
      }
    };

    if (this.appStateService.getAppState().getAuthenticationState().hasSession()) {
      const sub = this.appStateService.endSession(true, true).subscribe(() => {
        sub.unsubscribe();
        ch();
      });
    } else {
      ch();
    }
  }
  // noinspection JSMethodCanBeStatic
  getFlowButtonClass(flow: AuthenticationFlow): any {
    const retVal: any = {
      btn: true,
      'btn-auto': true,
    };
    // TODO: refactor to use configured image and application key, once they've been merged into the DelegatedAuthenticationChallenge
    if ((flow.challenges[0] as DelegatedAuthenticationChallenge).applicationName) {
      if ((flow.challenges[0] as DelegatedAuthenticationChallenge).applicationName === 'Facebook') {
        retVal['btn-auto'] = false;
        retVal['btn-facebook'] = true;
      } else if ((flow.challenges[0] as DelegatedAuthenticationChallenge).applicationName === 'Google') {
        retVal['btn-auto'] = false;
        retVal['btn-google'] = true;
      } else if ((flow.challenges[0] as DelegatedAuthenticationChallenge).applicationName === 'ADFS') {
        retVal['btn-auto'] = false;
        retVal['btn-adfs'] = true;
      }
    }
    return retVal;
  }

  first(fromTab?: ElementRef, noReset?: boolean) {
    const handler = () => {
        if (!noReset) {
          this.reset();
          this.readParams().then(v => {});
        }
        this.updateAuthenticationFlows(() => {
          const val = this.appStateService.getAppState().getAuthenticationState().getAuthenticationFlows();
          const params: UrlParams = LocationUtil.getParams();
          if (val && val.length && this.externalRedirect && !this.isResetPassword() &&
            !params || params[AppConstants.QP_ENABLE_FLOW] !== AppConstants.AC_AM_PASSWORD) {
            const notDisabled = val.filter((v) => !v.disabled);
            if (notDisabled.length === 1 &&
              notDisabled[0].challenges &&
              notDisabled[0].challenges.length &&
              notDisabled[0].challenges[0] &&
              notDisabled[0].challenges[0].authenticationMethod === AppConstants.AC_AM_DELEGATED) {
              // just one enabled flow which is a delegated flow.
              // There are currently no other flows that need special handling when navigating to first tab
              this.chooseFlow(notDisabled[0]);
            } else {
              this.showStep(undefined, true, fromTab);
            }
          } else {
            this.showStep(undefined, true, fromTab);
          }
        });
    };
    if (this.appStateService.getAppState().getAuthenticationState().hasSession()) {
      const sub = this.appStateService.endSession(true, true).subscribe(
        () => {
          sub.unsubscribe();
          handler();
        }
      );
    } else {
      handler();
    }

  }
  getAvailableFlows(): AuthenticationFlows {
    return this.appStateService.getAppState().getAuthenticationState().getAuthenticationFlows();
  }
  findFlowKeyByAuthenticationMethod(method: string): string {
    let retVal: string = null;

    const availableFlows = this.appStateService.getAppState().getAuthenticationState().getAuthenticationFlows()
      .filter((v) => !v.disabled);
    if (method && availableFlows && availableFlows.length) {
      for (let i = 0; i < availableFlows.length; i += 1) {
        let chall = availableFlows[i].challenges;
        while (chall && chall.length && chall[0]) {
          if (chall[0].authenticationMethod === method) {
            retVal = availableFlows[i].key;
            break;
          }
          chall = chall[0].challenges;
        }
        if (retVal) {
          break;
        }
      }
    }
    const params: UrlParams = LocationUtil.getParams();
    if (method === AppConstants.AC_AM_PASSWORD &&
      params &&
      params[AppConstants.QP_ENABLE_FLOW] === AppConstants.AC_AM_PASSWORD &&
      !retVal) {
      retVal = LoginComponent.ENABLED_PWD_FLOW.key;
    }
    return retVal;
  }

  showCustomChangeEmailNofication(): boolean {
    const next: string = ParamsService.getParamSync(AppConstants.QP_NEXT);
    if (!this.showServerError &&
      !this.showSignInFailed() &&
      !this.loginBeforeNextDismissed &&
      next &&
      next.indexOf(AppConstants.ROUTE_VALIDATE_RECOVERY_EMAIL) >= 0
    ) {
      let prmNext = ParamsService.getParamSync(AppConstants.QP_NEXT);
      if (!!prmNext && !LocationUtil.isValidNext(prmNext, this.configuration.getProperties().allowedOriginsForNextParam)) {
        this.logger.warn(
          'Invalid next param ignored %o',
          prmNext
        );
        prmNext = null;
      }
      const currentParams = new URL(window.location.href).searchParams;
      return prmNext !== null && (!currentParams || !currentParams.get(AppConstants.QP_ERROR));
    }
    return false;
  }

  continueWithAuth() {
    const t: ParamsForRouteNav|string = this.appStateService.getAppState().resolveNextRoute(
      AppConstants.AUHTENTICATION_PROCESS_QUERY_PARAMS)
    ;
    this.logger.debug('Continue after authenticated %o', t);
    if (typeof t === 'string') {
      this.processingService.show();
      window.location.href = t as string;
    } else if (t) {
      const tParams = t.queryParams;
      this.router.navigate([t.path], {
        queryParams: tParams,
        fragment: t.fragment,
      });
    }
  }

  canContinueAfterError() {
    // Continuing with the ERROR_OAUTH_INVALID_REDIRECT_URI is blocked.
    return this.showServerError !== AppConstants.ERROR_OAUTH_INVALID_REDIRECT_URI;
  }

  next(fromTab?: ElementRef) {
    this.updateAuthenticationFlows(() => {
      this.genericError = undefined;
      const anim = true;
      if (this.currentFlowStep) {
        if (!this.activeFlowKey) {
          // username screen is not a currentFlowStep and non default flows will set the key explicitly on user input,
          // so we must be here as a result of pressing next on the first challenge of the default flow. Turns out we can't
          // actually have a default flow, so we're forced to do things the hard way.
          this.activeFlowKey = this.findFlowKeyByAuthenticationMethod(this.currentFlowStep.authenticationMethod);
        }
        const challenge = this.nextChallenge(this.currentFlowStep.challenges);
        if (challenge) {
          this.showStep(challenge, anim, fromTab);
        } else {
          if (this.hasSession()) {
            // a session already exists and there are no challenges for the login component to handle
            // so there is nothing to submit and we can proceed without adding authentications.
            if (this.canContinueAfterError()) {
              this.continueWithAuth();
            }
          } else {
            this.submit();
          }
        }
      } else {
        let availableFlows: AuthenticationFlows = this.appStateService.getAppState().getAuthenticationState().getAuthenticationFlows();
        const params: UrlParams = LocationUtil.getParams();
        const pwdFlow = availableFlows ? availableFlows.find(
          (v) => v.challenges && v.challenges[0].authenticationMethod === AppConstants.AC_AM_PASSWORD
        ) : undefined;
        if (params && params[AppConstants.QP_ENABLE_FLOW] === AppConstants.AC_AM_PASSWORD &&
          (!availableFlows || !pwdFlow || pwdFlow.disabled)
        ) {
          if (!availableFlows) {
            availableFlows = [] as AuthenticationFlows;
          } else {
            availableFlows = [].concat(availableFlows);
          }
          this.enabledPWDFlow = cloneDeep(LoginComponent.ENABLED_PWD_FLOW) as AuthenticationFlow;
          availableFlows.unshift(this.enabledPWDFlow);
          this.showStep(
            this.nextChallenge(
              this.enabledPWDFlow.challenges
            ), anim, fromTab)
          ;
        } else if (availableFlows && availableFlows.length) {
          const notDisabled = availableFlows.filter((v) => !v.disabled);
          if (!this.isResetPassword() &&
            this.externalRedirect &&
            notDisabled.length === 1 &&
            notDisabled[0].challenges &&
            notDisabled[0].challenges.length &&
            notDisabled[0].challenges[0] &&
            notDisabled[0].challenges[0].authenticationMethod === AppConstants.AC_AM_DELEGATED) {
            // just one enabled flow which is a delegated flow.
            // There are currently no other flows that need special handling when navigating to next tab
            this.chooseFlow(notDisabled[0]);
          } else {
            this.showStep(
              this.nextChallenge(
                this.findFlowByKey(
                  this.activeFlowKey || this.findFlowKeyByAuthenticationMethod(AppConstants.AC_AM_PASSWORD)
                ).challenges
              ), anim, fromTab)
            ;
          }
        } else {
          throw new Error('LoginComponent next: No available authentication flows');
        }
      }
    });
  }

  isLastStep(): boolean {
    return this.currentFlowStep && (!this.currentFlowStep.challenges ||
      !this.currentFlowStep.challenges.filter(
        (val) => val.authenticationMethod !== AppConstants.AC_AM_VERIFY_EMAIL && val.authenticationMethod !== AppConstants.AC_AM_ACCEPT_AGREEMENTS).length
      )
    ;
  }

  isActiveFlowStep(name?: string): boolean {
    let retValue;
    if (this.currentFlowStep) {
      retValue = !!(name && name === this.currentFlowStep.authenticationMethod);
    } else {
      retValue = !name;
    }
    return retValue;
  }

  submit() {
    const totpRequired = this.isTotpRequired();
    const totpInputIsValid = this.hasValidTotp();
    if (!totpRequired || totpInputIsValid) {
      this.authenticate(totpRequired && totpInputIsValid);
    }
  }

  sendEmail() {
    this.emailfailed = false;
    this.emailsent = false;
    const resetUri = [];
    resetUri.push(LocationUtil.getOwnDomainURL());
    resetUri.push(getAppBaseHref());
    resetUri.push(AppConstants.ROUTE_RESET_PASSWORD);
    if (resetUri[0].indexOf('?') < 0) {
      resetUri.push('?');
    } else {
      resetUri.push('&');
    }
    resetUri.push(AppConstants.QP_RESET_PASSWORD_KEY);
    resetUri.push('={0}&');
    resetUri.push(AppConstants.QP_EMAIL);
    resetUri.push('=');
    resetUri.push('{1}');

    resetUri.push('&');
    resetUri.push(AppConstants.UI_SESSION_ID);
    resetUri.push('=');
    resetUri.push(this.appStateService.getAppState().uiSessionId);

    resetUri.push(
      LocationUtil.validParamsToQueryString('&',
        [AppConstants.QP_EMAIL, AppConstants.QP_LOGIN_HINT,  AppConstants.QP_RESET_PASSWORD_KEY, AppConstants.UI_SESSION_ID],
        this.configuration.getProperties().allowedOriginsForNextParam)
    );
    const obj: SendEmailForResetPasswordRequest = {
      userName: this.defaultForm.get('userName').value,
      resetUri: resetUri.join(''),
    };
    this.passwordApi.sendEmailForResetPassword(obj, 'response').subscribe(response => {
      this.emailsent = true;
      this.sendEmailTimeout = LoginComponent.RESEND_TIMEOUT;
      this.sendEmailTimeoutCounter = timer(1000, 1000).subscribe(() => {
        this.sendEmailTimeout--;
        if (this.sendEmailTimeout <= 0) {
          this.sendEmailTimeout = undefined;
          this.sendEmailTimeoutCounter.unsubscribe();
          this.sendEmailTimeoutCounter = undefined;
        }
      });
    }, error => {
      this.emailfailed = true;
    });
  }
  showSecondaryFlows(): boolean {
    return this.appStateService.getAppState().getAuthenticationState().getAuthenticationFlows() &&
      this.appStateService.getAppState().getAuthenticationState().getAuthenticationFlows().length > 1;
    // alternative for hiding secondaries, instead of automatic call to log out on click
    // return !this.activeFlowKey && availableFlows && availableFlows.length > 1;
  }
  allowRegistration(): boolean {
    return this.allowRegister;
  }
  private reset() {

    this.enabledPWDFlow = undefined;
    this.activeFlowKey = undefined;
    this.currentFlowStep = undefined;
    this.defaultForm.reset();
    this.passwordForm.reset();
    this.resetPasswordForm.reset();
    this.totpForm.reset();

    this.invalidTotpError = undefined;
    this.invalidPasswordError = undefined;
    this.tooManyAttemptsError = undefined;
    this.tooManyAttemptsTimeout = undefined;
    if (this.tooManyAttemptsTimeoutCounter) {
      this.tooManyAttemptsTimeoutCounter.unsubscribe();
    }
    this.tooManyAttemptsTimeoutCounter = undefined;
    this.invalidCodeError = undefined;
    this.genericError = undefined;
    this.emailsent = false;
    this.emailfailed = false;
  }


  private readParams(anim?: boolean): Promise<LoginComponentParams> {
    return new Promise<LoginComponentParams>((resolve) => {
      const usernamePromise = this.paramsService.getParam(AppConstants.QP_EMAIL);
      const loginHintPromise = this.paramsService.getParam(AppConstants.QP_LOGIN_HINT);
      const resetKeyPromise = this.paramsService.getParam(AppConstants.QP_RESET_PASSWORD_KEY);
      const invTokenPromise = this.paramsService.getParam(AppConstants.QP_INVITATION_TOKEN);
      const requirePromise = this.paramsService.getParam(AppConstants.QP_REQUIRE_CHALLENGE);
      const showRememberMePromise = this.paramsService.getParam(AppConstants.QP_SHOW_REMEMBER_ME);
      const rememberMePromise = this.paramsService.getParam(AppConstants.QP_REMEMBER_ME);
      const externalRedirectPromise = this.paramsService.getParam(AppConstants.QP_EXTERNAL_REDIRECT);
      const errorPromise = this.paramsService.getParam(AppConstants.QP_ERROR);
      const allowRegisterPromise = this.paramsService.getParam(AppConstants.QP_ALLOW_REGISTER);


      Promise.all([
        usernamePromise,
        resetKeyPromise,
        invTokenPromise,
        requirePromise,
        showRememberMePromise,
        rememberMePromise,
        externalRedirectPromise,
        errorPromise,
        loginHintPromise,
        allowRegisterPromise,
      ]).then((values) => {
        this.resetKeyParam = values[1];
        const result: LoginComponentParams = {
          username: values[0] || values[8],
          resetKey: values[1],
        };
        if (values[2] && values[3] === AppConstants.AC_AM_INVITATION_ACCEPTED && values[0]) {
          this.userNameAsFixed = true;
        }
        if (values[4] === 'true') {
          this.showRememberMe = true;
        } else if (values[4] === 'false') {
          this.showRememberMe = false;
        }
        if (values[5] === 'true') {
          this.defaultForm.get('rememberMe').setValue(true);
        } else if (values[5] === 'false') {
          this.defaultForm.get('rememberMe').setValue(false);
        }
        if (values[6] === 'false') {
          this.externalRedirect = false;
        } else {
          this.externalRedirect = true;
        }
        if (!!values[7]) {
          this.setShowServerError(values[7]);
        } else if (this.showServerError && this.showServerError !== 'error') {
          // only clear previous parameter based errors
          this.setShowServerError(undefined);

        }
        if (values[9] === 'false') {
          this.allowRegister = false;
        } else {
          this.allowRegister = true;
        }
        if (result.resetKey) {
          this.resetPasswordForm.get('code').setValue(result.resetKey);
        }

        if (result.username) {
          this.defaultForm.get('userName').setValue(result.username);
        }

        resolve(result);
      });
    });
  }

  private nextChallenge(challenges: Array<AuthenticationChallenge>): AuthenticationChallenge {
    return challenges && challenges.length > 0 ? this.selectChallenge(challenges[0]) : undefined;
  }

  private selectChallenge(challenge: AuthenticationChallenge): AuthenticationChallenge {
    return challenge.authenticationMethod === AppConstants.AC_AM_VERIFY_EMAIL ||
      challenge.authenticationMethod === AppConstants.AC_AM_ACCEPT_AGREEMENTS
        ? this.nextChallenge(challenge.challenges)
        : challenge;
  }

  public getCurrentFlowStepTab(): ElementRef {
      return this.getTabByAuthenticationChallenge(this.currentFlowStep);
  }

  private getTabByAuthenticationChallenge(authc: AuthenticationChallenge): ElementRef {
    return this.getTabByAuthenticationMethod(authc ? authc.authenticationMethod : null);
  }
  private getTabByAuthenticationMethod(authm: string): ElementRef {
    let retVal: ElementRef = null;
    switch (authm) {
      case AppConstants.AC_AM_PASSWORD:
        retVal = this.passwordTab;
        break;
      case AppConstants.AC_AM_TOTP:
        retVal = this.totpTab;
        break;
      default:
        retVal = this.usernameTab;
        break;
    }
    return retVal;
  }

  private showStep(stepForChallenge?: AuthenticationChallenge, animation?: boolean, fromTab?: ElementRef) {
    const oldTab = fromTab || this.getCurrentFlowStepTab();
    const anim = animation !== false;
    this.currentFlowStep = stepForChallenge;
    const newTab = this.getCurrentFlowStepTab();
    if (!this.disableAnimations() && anim && newTab && oldTab && oldTab.nativeElement.id !== newTab.nativeElement.id) {
      let animationFactory: AnimationFactory;
      let headerAnimationFactory: AnimationFactory;
      if (newTab === this.usernameTab) {
        animationFactory = this.animationBuilder.build(
          createTabSlideDownAndFadeInTransitionSteps(oldTab, newTab)
        );
        if (this.restartBtn && this.restartBtn.nativeElement.className.indexOf('active') >= 0) {
          headerAnimationFactory = this.animationBuilder.build([
            query(':self', [
              style({height: this.cardHeader.nativeElement.offsetHeight + 'px'}),
              animate('200ms 0ms ease-in-out',
                style({
                  height: (this.cardHeader.nativeElement.offsetHeight - this.restartBtn.nativeElement.offsetHeight) + 'px'
                })
              ),
              style({height: 'unset'}),
            ]),
          ]);
        }
      } else {
        animationFactory = this.animationBuilder.build(
          createTabSlideUpAndFadeInTransitionSteps(oldTab, newTab)
        );
        if (this.restartBtn && this.restartBtn.nativeElement.className.indexOf('active') < 0) {
          headerAnimationFactory = this.animationBuilder.build([
            query(':self', [
              style({height: this.cardHeader.nativeElement.offsetHeight + 'px'}),
              animate('200ms 0ms ease-in-out',
                style({
                  height: (this.cardHeader.nativeElement.offsetHeight + this.restartBtn.nativeElement.offsetHeight) + 'px'
                })
              ),
              style({height: 'unset'}),
            ]),
          ]);
        }
      }
      const anm: any = animationFactory.create(this.tabHolder.nativeElement);
      anm.onDone(() => {
        anm.reset();
        StandaloneComponent.setFocusToElemOrChildControl(this.hostElement, this.focusSelectorPrefix);
      });
      if (headerAnimationFactory) {
        const hAnim = headerAnimationFactory.create(this.cardHeader.nativeElement);
        hAnim.onDone(() => {
          hAnim.reset();
        });
        hAnim.play();
      }
      anm.play();
    }
    setTimeout(() => {
      StandaloneComponent.setFocusToElemOrChildControl(this.hostElement, this.focusSelectorPrefix);
    }, 100);
  }


  private authenticate(withTotp: boolean) {

    this.emailfailed = false;
    this.emailsent = false;

    let userNameAndPasswordCredential = null;
    if (this.isResetPassword()) {
      userNameAndPasswordCredential = {
        authenticationMethod: AppConstants.AC_AM_RESET_PWD,
        userName: this.defaultForm.get('userName').value,
        password: this.resetPasswordForm.get('passwordForResetPassword').value,
        code: this.resetPasswordForm.get('code').value,
      } as UserNameAndPasswordCredentialForResetPassword;
    } else {
      userNameAndPasswordCredential = {
        authenticationMethod: AppConstants.AC_AM_PASSWORD,
        userName: this.defaultForm.get('userName').value,
        password: this.passwordForm.get('password').value,
      } as UserNameAndPasswordCredential;
    }

    const credentials = [userNameAndPasswordCredential] as Credentials;

    if (withTotp) {
      const totpCredential = {
        authenticationMethod: AppConstants.AC_AM_TOTP,
        oneTimePassword: this.totpForm.get('totp').value.replace(/\s/g, ''),
      } as TimeBasedOneTimePasswordCredential;
      credentials.push(totpCredential);
    }

    const remember = this.defaultForm.get('rememberMe').value;
    const returnUser = true;
    this.logger.debug('Add authentications (return user: %o), remember = %s: %O',
      returnUser, remember, this.sanitizeCredentials(credentials));
    const addAuthenticationsRequest: Observable<HttpResponseBase> =
      this.appStateService.addAuthentications(credentials, remember, returnUser)
        .pipe(catchError(error => ResponseUtil.handleAuthenticationChallengeResponse(error)));
    addAuthenticationsRequest.subscribe(response => {
      this.invalidPasswordError = undefined;
      this.invalidTotpError = undefined;
      this.invalidCodeError = undefined;
      this.genericError = undefined;
      this.tooManyAttemptsError = undefined;
      this.tooManyAttemptsTimeout = undefined;
      if (this.tooManyAttemptsTimeoutCounter) {
        this.tooManyAttemptsTimeoutCounter.unsubscribe();
      }
      this.tooManyAttemptsTimeoutCounter = undefined;
      const isErrorResponse = response instanceof HttpErrorResponse;
      if (isErrorResponse) {
        const erResp: HttpErrorResponse = response as HttpErrorResponse;
        this.logger.debug('Login error: %O', erResp);
        this.tooManyAttemptsError = 'authentication_attempts_restricted' === erResp.error.error;
        this.invalidCodeError = 'reset_password_not_authorized' === erResp.error.error;
        this.invalidTotpError = 'invalid_code' === erResp.error.error
          ? {} as TimeBasedOneTimePasswordChallenge
          : undefined
        ;
        this.invalidPasswordError = 'invalid_username_or_password' === erResp.error.error
          ? {} as UserNameAndPasswordChallenge
          : undefined
        ;
        /** @namespace erResp.error.waitSeconds **/
        if (erResp.error.waitSeconds > 2) {
          // better to not react if less than 2 seconds, as there is not enough time for the user to read the info
          this.tooManyAttemptsTimeout = erResp.error.waitSeconds;
          this.tooManyAttemptsTimeoutCounter =  timer(1000, 1000).subscribe(() => {
            this.tooManyAttemptsTimeout--;
            if (this.tooManyAttemptsTimeout <= 0) {
              this.tooManyAttemptsTimeout = undefined;
              this.tooManyAttemptsTimeoutCounter.unsubscribe();
              this.tooManyAttemptsTimeoutCounter = undefined;
            }
          });
        }
      }
      const challenges = ResponseUtil.readChallenges(response).required;
      if (challenges && challenges.length > 0) {
        const params: UrlParams = LocationUtil.getParams();
        if (params && params[AppConstants.QP_ENABLE_FLOW] === AppConstants.AC_AM_PASSWORD) {
          if (!this.enabledPWDFlow) {
            this.enabledPWDFlow = cloneDeep(LoginComponent.ENABLED_PWD_FLOW);
          }
          const pwdChallenge = this.enabledPWDFlow.challenges.find(
            challenge => AppConstants.AC_AM_PASSWORD === challenge.authenticationMethod);
          if (!pwdChallenge) {
            throw new Error('Invalid enabled pwd flow');
          } else {
            pwdChallenge.challenges = challenges.filter((v) => v.authenticationMethod !== AppConstants.AC_AM_DELEGATED);
          }
        }
        this.logger.debug('Required challenges received in authentication response: %O', challenges);
        this.invalidPasswordError = challenges.find(challenge => AppConstants.AC_AM_PASSWORD === challenge.authenticationMethod);
        if (!this.invalidTotpError) {
          this.invalidTotpError = challenges.find(challenge => AppConstants.AC_AM_TOTP === challenge.authenticationMethod);
          if (!this.invalidTotpError && this.invalidPasswordError && this.invalidPasswordError.challenges) {
            this.invalidTotpError = this.invalidPasswordError.challenges.find(
              (challenge) => AppConstants.AC_AM_TOTP === challenge.authenticationMethod);
          }
        }
      }
      if (this.invalidPasswordError || this.invalidTotpError || this.invalidCodeError || this.tooManyAttemptsError) {
        this.genericError = true;
        this.showError();
      } else {
        this.enabledPWDFlow = undefined;
        this.continueWithAuth();
      }
    }, err => {
      this.genericError = true;
    });
  }
  private showError() {
    if (this.invalidPasswordError || this.invalidCodeError) {
      this.showErrorStep(AppConstants.AC_AM_PASSWORD);
    } else if (this.invalidTotpError) {
      this.showErrorStep(AppConstants.AC_AM_TOTP);
    } else if (this.tooManyAttemptsError) {
      this.logger.debug('Too many attempts should be shown somewhere');
    }
  }

  private showErrorStep(step?: string) {
    const flow = this.findFlowByKey(this.activeFlowKey || this.findFlowKeyByAuthenticationMethod(AppConstants.AC_AM_PASSWORD));
    this.showStep(this.findFirstChallengeByAuthenticationMethod(step, flow.challenges));
  }

  private findFlowByKey(key: string): AuthenticationFlow {
    let retVal: AuthenticationFlow = null;
    if (key === LoginComponent.ENABLED_PWD_FLOW.key) {
      if (!this.enabledPWDFlow) {
        this.enabledPWDFlow = cloneDeep(LoginComponent.ENABLED_PWD_FLOW);
      }
      retVal = this.enabledPWDFlow;
    } else {
      if (this.appStateService.getAppState().getAuthenticationState().getAuthenticationFlows() && key) {
        const flows: AuthenticationFlow[] = this.appStateService.getAppState().getAuthenticationState().getAuthenticationFlows()
          .filter((flow) => {
            return flow.key === key;
          })
        ;
        retVal = flows.length ? flows[0] : null;
      }
    }
    return retVal;
  }

  private findFirstChallengeByAuthenticationMethod(
    authnMethod: string,
    challenges: AuthenticationChallenge[]): AuthenticationChallenge {
    let retValue;
    if (challenges) {
      for (const challenge of challenges) {
        const match = this.findChallengeOrFirstChildChallengeByAuthenticationMethod(authnMethod, challenge);
        if (match) {
          retValue = match;
          break;
        }
      }
    }
    return retValue;
  }

  private findChallengeOrFirstChildChallengeByAuthenticationMethod(
    authnMethod: string,
    challenge: AuthenticationChallenge): AuthenticationChallenge {
    return authnMethod === challenge.authenticationMethod
      ? challenge
      : this.findFirstChallengeByAuthenticationMethod(authnMethod, challenge.challenges);
  }

  private hasValidTotp(): boolean {
    return this.totpForm.get('totp').value !== '' && this.totpForm.get('totp').valid;
  }

  private isTotpRequired(): boolean {
    let retValue;
    const activeFlow = this.findFlowByKey(this.activeFlowKey || this.findFlowKeyByAuthenticationMethod(AppConstants.AC_AM_PASSWORD));
    if (activeFlow) {
      const totpChallenge = this.findChallengeByAuthenticationMethod(activeFlow.challenges[0], AppConstants.AC_AM_TOTP);
      retValue = totpChallenge !== null;
    } else {
      retValue = false;
    }
    return retValue;
  }

  private findAuthenticationFlowStartingWithChallenge(
    authnFlows: AuthenticationFlows, authChallenge: AuthenticationChallenge): AuthenticationFlow {
    return authnFlows.find(
      flow => {
        return flow.challenges &&
               flow.challenges.length > 0 &&
               ((authChallenge.authenticationMethod !== AppConstants.AC_AM_DELEGATED &&
                 authChallenge.authenticationMethod === flow.challenges[0].authenticationMethod) ||
                 (authChallenge.authenticationMethod === AppConstants.AC_AM_DELEGATED &&
                  (authChallenge as DelegatedAuthenticationChallenge).applicationId ===
                    (flow.challenges[0] as DelegatedAuthenticationChallenge).applicationId))
        ;
      })
    ;
  }

  private findChallengeByAuthenticationMethod(challenge: AuthenticationChallenge, authenticationMethod: string): AuthenticationChallenge {
    let retValue = null;
    if (authenticationMethod === challenge.authenticationMethod) {
      retValue = challenge;
    } else if (challenge.challenges) {
      for (const childChallenge of challenge.challenges) {
        retValue = this.findChallengeByAuthenticationMethod(childChallenge, authenticationMethod);
        if (retValue) {
          break;
        }
      }
    }
    return retValue;
  }

  private updateAuthenticationFlows(callback?: () => void) {
    const userName = this.defaultForm.get('userName').value;
    this.logger.debug('Updating authentication status from login for: %s', userName || 'anonymous');
    // only update state if requested email is different from the existing
    if (this.appStateService.getAppState().getAuthenticationState().getProfile() &&
      this.appStateService.getAppState().getAuthenticationState().getProfile().email !== userName) {
      this.appStateService.fetchAuthenticationState(userName, (val) => {
        // handled invitation should remain until logout, so copy them in from the old state.
        val.setSkippedInvitations(this.appStateService.getAppState().getAuthenticationState().getSkippedInvitations());
        val.setDeclinedInvitations(this.appStateService.getAppState().getAuthenticationState().getDeclinedInvitations());
        val.setAcceptedInvitations(this.appStateService.getAppState().getAuthenticationState().getAcceptedInvitations());
        this.appStateService.getAppState().updateAuthenticationState(val);
        if (this.showServerError === 'error') {
          // only clear the error if it is caused by fetchAuthenticationState
          this.setShowServerError(undefined);
        }
        if (callback) {
          callback();
        }
      }, error => {
        this.setShowServerError('error');
      });
    } else if (callback) {
      callback();
    }
  }
  public setShowServerError(error: string|undefined) {
    if (error === undefined) {
      const t = this.stripErrorParamFromCurrentUrl();
      if (t !== window.location.href) {
        window.history.replaceState({}, document.title, t);
      }
    }
    this.showServerError = error;
  }

  private validateConfirmPassword(control: AbstractControl): ValidationErrors | null {
    const pwField = this.resetPasswordForm.get('passwordForResetPassword');
    const cpwField = this.resetPasswordForm.get('confirmPassword');
    return cpwField.value === pwField.value
      ? null
      : { 'mustMatch': true };
  }

  public hasTooManyAttemptsTimeout(): boolean {
    return this.tooManyAttemptsTimeout > 0;
  }
  public resolveWindowTitlePart(): Observable<string|undefined> {
    if (this.isResetPassword()) {
      return this.translate.get('login.window-title.reset-password');
    } else {
      return this.translate.get('login.window-title.default');
    }
  }

}
