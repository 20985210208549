import {AppConstants} from '../app/AppConstants';


let originalBaseHref = null;
let appBaseHref = null;

/**
 * Copied in from localeUtil to avoid circular deps
 */
function getLocalePathParam(): string {
  if (window.location.pathname && window.location.pathname.length > 1) {
    const potential_locale = window.location.pathname.split('/')[1];
    //Regex tailored to allow az, az-az, and az-az9 (last one any combination of three alphanumeric throug a-z, 0-9)
    if (potential_locale && potential_locale.toLowerCase().match(/^[a-z]{2}(-[a-z]{2}|-[a-z0-9]{3})?$/)) {
      return potential_locale;
    }
  }
  return null;
}

export function initAppBaseRef() {
  if (originalBaseHref === null) {
    const base_href_node = document.getElementById('base-href') as any;
    if (!base_href_node) {
      throw new Error('Base href element is required but not found from head.');
    }
    originalBaseHref = base_href_node.getAttribute('href');
    const loc = getLocalePathParam();
    if (loc) {
      appBaseHref = '/' + loc + originalBaseHref;
      base_href_node.href = appBaseHref;
    } else {
      appBaseHref = originalBaseHref;
    }
  }
}
export function getOriginalBaseHref(): string {
  return originalBaseHref;
}

export function getAppBaseHref(): string {
  return appBaseHref;
}


export interface UrlParams {
  [key: string]: string;
}
export default class LocationUtil {
  public static getOwnDomainURL(): string {
    const result = [];
    result.push(window.location.protocol);
    result.push('//');
    result.push(window.location.hostname);
    if (window.location.port) {
      result.push(':');
      result.push(window.location.port);
    }
    return result.join('');
  }
  public static getParams(excludeParams?: Array<string>): UrlParams  {
    const result = {};
    const splittedURL = window.location.href.split('?');
    if (splittedURL.length > 1) {
      const t = splittedURL[1].split('&');
      for (let i = 0; i < t.length; i += 1) {
        const t2 = t[i].split('=');
        if (!excludeParams || excludeParams.indexOf(t2[0]) < 0) {
          result[t2[0]] = t2.length > 1 ? t2[1] : undefined;
        }
      }
    }
    return result;
  }
  public static isValidNext(next: string, allowedDomains: string[] = []): boolean {
    const n = new URL(next, LocationUtil.getOwnDomainURL());
    const allowed = [new URL(LocationUtil.getOwnDomainURL()), ...allowedDomains.map((ad) => {
      let url: URL | null;
      try {
        url = new URL(ad, LocationUtil.getOwnDomainURL());
      } catch (err)  {
        url = null;
      }
      return url;
    })];
    let retVal = false;
    if (n.protocol === 'http:' || n.protocol === 'https:') {
      // all other protocols are blocked, regardless of conf
      for (let i = 0; i < allowed.length; i += 1) {
        const a = allowed[i];
        if (!!a && a.protocol === n.protocol && a.host === n.host && a.port === n.port) {
          retVal = true;
          break;
        }
      }
    }
    return retVal;
  }
  public static validParamsToQueryString(
    leadingCharacter?: '?'|'&'|null,  excludeParams?: Array<string>, allowedDomainsForNext: string[] = []): string {
    const params: UrlParams = LocationUtil.getParams(excludeParams);
    const keys = Object.keys(params);
    const retVal: string[] = [];
    for (let i = 0; i < keys.length; i += 1) {
      if (keys[i] === AppConstants.QP_NEXT) {

        if (LocationUtil.isValidNext(decodeURIComponent(params[keys[i]]), allowedDomainsForNext)) {
          retVal.push('&');
          retVal.push(keys[i]);
          retVal.push('=');
          retVal.push(params[keys[i]]);
        }
      } else {
        retVal.push('&');
        retVal.push(keys[i]);
        retVal.push('=');
        retVal.push(params[keys[i]]);
      }
    }
    if (retVal.length > 0) {
      if (leadingCharacter !== '&') {
        retVal.shift();
        if (leadingCharacter === '?') {
          retVal.unshift(leadingCharacter);
        }
      }
    }
    return retVal.join('');
  }
}
