/**
 * 10Duke End User Identity API
 * Authenticate user and manage authenticated sessions
 *
 * OpenAPI spec version: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */import { Credential } from './credential';


/**
 * Authentication by code sent to the user for the purpose of authorizing password reset. Supplying these credentials will authenticate the user and set the given password as the new password of the user. The authentication method name for this authentication, as used when adding authentication (i.e. when resetting the password), is \"resetPassword\". As result of successful authentication, authentication is granted as when using the \"password\" authentication.
 */
export interface UserNameAndPasswordCredentialForResetPassword extends Credential { 
    /**
     * User name
     */
    userName: string;
    /**
     * The new password. If a password was given in the first step of the \"forgot password\" process, the same password must be given here. By default, the password must be at least eight characters long, and have at least one of: small letter, capital letter, digit, special character. This default can be overridden in configuration, in which case the \"pattern\" field will reflect the updated configuration.
     */
    password: string;
    /**
     * Code sent to the user by email
     */
    code: string;
    /**
     * Key identifying use case for the purpose of messaging
     */
    messageKey?: UserNameAndPasswordCredentialForResetPassword.MessageKeyEnum;
}
export namespace UserNameAndPasswordCredentialForResetPassword {
    export type MessageKeyEnum = 'ResetPasswordComplete' | 'ActivateUserComplete';
    export const MessageKeyEnum = {
        ResetPasswordComplete: 'ResetPasswordComplete' as MessageKeyEnum,
        ActivateUserComplete: 'ActivateUserComplete' as MessageKeyEnum
    };
}